import { userConstants } from '../../_constants';

var initialState = {
    isRootlessness: false,
    isPlacementTest: false,
    proposedCurriculum: false,
    resultPlacementTest: {},
    chooseListCurriculum: false,
    sideBarRootLessness: false,
};

const rootlessness = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.SHOW_SIDEBAR_ROOTLESSNESS:
            return {
                ...state,
                sideBarRootLessness: true,
            };
        case userConstants.HIDE_SIDEBAR_ROOTLESSNESS:
            return {
                ...state,
                sideBarRootLessness: false,
            };
        case userConstants.ON_ROOTLESSNESS:
            return {
                ...state,
                isRootlessness: true,
            };
        case userConstants.OFF_ROOTLESSNESS:
            return {
                ...state,
                isRootlessness: false,
            }
        case userConstants.ON_PLACEMENT_TEST:
            return {
                ...state,
                isPlacementTest: true
            }
        case userConstants.OFF_PLACEMENT_TEST:
            return {
                ...state,
                isPlacementTest: false
            }
        case userConstants.ON_PROPOSED_CURRICULUM: {
            return {
                ...state,
                proposedCurriculum: true
            }
        }
        case userConstants.OFF_PROPOSED_CURRICULUM: {
            return {
                ...state,
                proposedCurriculum: false
            }
        }
        case userConstants.ON_CHOOSE_LIST_CURRICULUM: {
            return {
                ...state,
                chooseListCurriculum: true
            }
        }
        case userConstants.OFF_CHOOSE_LIST_CURRICULUM: {
            return {
                ...state,
                chooseListCurriculum: false
            }
        }
        case userConstants.RESULT_PLACEMENT_TEST: {
            return {
                ...state,
                resultPlacementTest: action.data
            }
        }
        case userConstants.RESET_RESULT_PLACEMENT_TEST: {
            return {
                ...state,
                resultPlacementTest: {}
            }
        }
        case userConstants.RESET_ALL_PLACEMENT_TEST: {
            return initialState
        }
        default: return state;
    }
};

export { rootlessness };