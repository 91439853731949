import { useState } from "react"
import { registerValue } from "../../../../_constants/register"

export const ChooseOrgLogic = () => {
  const [organizationList, setOrganizationList] = useState(Object.values(registerValue))
  const [orgHover, setOrgHover] = useState(null)

  return {
    organizationList,
    orgHover,
    setOrgHover
  }
}