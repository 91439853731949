import { userConstants } from "./../../_constants";
var initialState = {
  fullname: "",
  gender: "",
  birthday: "",
  email: "",
  phone: "",
  school: "",
  base_url: "",
  user_info: {
    data: [],
  },
  user_info_news: {
    data: [],
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.GET_PROFILE:
      return {
        ...state,
        base_url: action.base_url,
        user_info: {
          data: action.data,
        },
      };
    case userConstants.GET_PROFILE_NEWS:
      return {
        ...state,
        base_url: action.base_url,
        user_info_news: {
          data: action.data,
        },
      };
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { profile };
