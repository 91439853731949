import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { removeVietnameseDiacritics } from "../../../_base/Validate";

export const modalSearchLogic = (props) => {
  let { listDataOptions } = props;

  // Variable search title
  const [valueSearch, setValueSearch] = useState("");

  // Variable List Data Side
  const [listDataOptionsSide, setListDataOptionsSide] =
    useState(listDataOptions);

  useEffect(() => {
    setListDataOptionsSide(listDataOptions);
  }, [listDataOptions]);

  // Func filter title
  function searchTitle(searchInput) {
    if (isEmpty(searchInput)) {
      return listDataOptions;
    } else {
      const normalizedSearchInput = removeVietnameseDiacritics(
        searchInput.toLowerCase()?.trim()
      );
      const filteredTitles = listDataOptions.filter((item) => {
        const normalizedTitle = removeVietnameseDiacritics(
          item.title.toLowerCase()?.trim()
        );
        // Include both 'd' and 'đ' when searchInput is 'd'
        if (normalizedSearchInput == "d") {
          return normalizedTitle.includes("d") || normalizedTitle.includes("đ");
        } else {
          return normalizedTitle.includes(normalizedSearchInput);
        }
      });

      return filteredTitles;
    }
  }

  // Func Reset value search
  const handleResetValueSearch = () => {
    setValueSearch("");
    setListDataOptionsSide(searchTitle(""));
  };

  // Func Change value search
  const changeValueSearch = (newValue) => {
    setValueSearch(newValue);
    console.log(
      "removeVietnameseDiacritics(newValue?.toLowerCase()?.trim()) ====",
      removeVietnameseDiacritics(newValue?.toLowerCase()?.trim())
    );
    setListDataOptionsSide(
      searchTitle(removeVietnameseDiacritics(newValue?.toLowerCase()?.trim()))
    );
  };

  return {
    listDataOptionsSide,
    valueSearch,
    setValueSearch,
    handleResetValueSearch,
    changeValueSearch,
  };
};
