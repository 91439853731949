import { apiCaller } from "./../_helpers";
import {
  configConstants,
  teacherConstants,
  userConstants,
  curriculumConstants,
  popupConstants,
  scheduleConstants,
} from "./../_constants";
import { alertActions } from "./";
import { history } from "../_helpers";
import { teacherService } from "../_services";
import moment from "moment";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { isEmpty } from "lodash";
import $ from "jquery";

export const teacherActions = {
  getListClass,
  getListClassOffline,
  getDetailClass,
  addClass,
  updateClassById,
  getStudentOfClass,
  addStudent,
  checkStudent,
  getRequestStudent,
  acceptRequestStudent,
  rejectRequestStudent,
  removeStudent,
  removeStudentOffline,
  getCurriculum,
  getCurriculumV2,
  getCurriculumPersonal,
  addCurriculum,
  removeCurriculum,
  getDetailCurriculumOfClass,
  editCurriculum,
  getDetailCurriculumById,
  getCurriculumFavorite,
  getCurriculumFavoriteV2,
  filterSkill,
  getHistoryExercise,
  getDetailExercise,
  udpateExercise,
  getFileExercise,
  addExercise,
  getListHomeWork,
  getDetailHomeWork,
  getDetailHomeWorkExercise,
  updatelHomeWorkExercise,
  getProfile,
  updateProfile,
  callAIHomeWork,
  getStudentRanking,
  getClassRanking,
  getDetailClassOffline,
  createRollUp,
  updateRollUp,
  getExamcardOffline,
  createExamCard,
  updateExamCard,
  deleteFileExercise,
  addFileResource,
  getReportClass,
  getReportByStudentId,
  getReportByStudentIdByDateTime,
  getLogLearningByStudentId,
  resetPassword,
  getHistoryExerciseWithoutClass,
  getDetailExerciseWithoutClass,
  updateCriteria,
  getListCriteria,
  remindStudent,
  addWish,
  removeWish,
  getRollUpById,
  deleteClassById,
  getProfileNews,
  getLogLearningByDate,
  getProfileV2,
};

function hasDuplicates(array1, array2) {
  var combinedArray = array1.concat(array2);
  return new Set(combinedArray).size !== combinedArray.length;
}

function getListClass(screen, exercises = false, loadingCommond = true) {
  !loadingCommond && $(".loading-side").removeClass("hide");
  return (dispatch) => {
    return apiCaller(
      `/api_teacher/my_classes?screen=${screen}`,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      loadingCommond
    ).then((classes) => {
      dispatch({
        type: teacherConstants.GET_LIST_CLASS,
        classes: classes,
      });

      if (exercises === true) {
        if (classes.data.length === 1) {
          history.push(
            "/teacher/class/view/" + classes.data[0].id + "/exercise/student"
          );
        } else {
          history.push("/teacher/curriculum/exercise/class");
        }
      }

      !loadingCommond && $(".loading-side").addClass("hide");
    });
  };
}

function getListClassOffline() {
  return (dispatch) => {
    return apiCaller(
      "/api_class_offline/my_classes",
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      false
    ).then((classes) => {
      dispatch({
        type: teacherConstants.GET_LIST_CLASS_OFFLINE,
        classes: classes,
      });
    });
  };
}

function getDetailClass(id, type) {
  return (dispatch) => {
    return apiCaller("/api_class/class?id=" + id, "GET", {}, null, true).then(
      (dataClass) => {
        // console.log(dataClass);
        dispatch({
          type: teacherConstants.GET_DETAIL_CLASS,
          class: dataClass.data,
        });
        if (type !== "ONLY_DATA_CLASS") {
          dispatch(
            teacherActions.getDetailCurriculumOfClass(
              dataClass.data.curriculum_id
            )
          );
        }
      }
    );
  };
}

function getDetailClassOffline(id) {
  return (dispatch) => {
    return apiCaller(
      "/api_class_offline/class?id=" + id,
      "GET",
      {},
      null,
      true
    ).then((dataClass) => {
      dispatch({
        type: teacherConstants.GET_DETAIL_CLASS_OFFLINE,
        class: dataClass,
      });
    });
  };
}

function addClass(data, page) {
  return (dispatch) => {
    return apiCaller("/api_class/class", "POST", data, null, true).then(
      () => {
        if (page == "homepage") {
          dispatch(
            alertActions.success({
              message: teacherConstants.MESSAGE_ADD_CLASS_COMPLETE,
              screen: teacherConstants.SCREEN_HOME_PAGE,
            })
          );
        } else {
          dispatch(
            alertActions.success({
              message: teacherConstants.MESSAGE_ADD_CLASS_COMPLETE,
              screen: teacherConstants.SCREEN_ADD_CLASS,
            })
          );
        }
      },
      (error) => {
        if (page == "homepage") {
          dispatch(
            alertActions.error({
              message: error.toString(),
              screen: teacherConstants.SCREEN_HOME_PAGE,
            })
          );
        } else {
          dispatch(
            alertActions.error({
              message: error.toString(),
              screen: teacherConstants.SCREEN_ADD_CLASS,
            })
          );
        }
      }
    );
  };
}

function updateClassById(data) {
  return (dispatch) => {
    return apiCaller("/api_class/class_edit", "POST", data, null, true).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Chỉnh sửa lớp học thành công",
            screen: teacherConstants.SCREEN_ADD_CLASS,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_CLASS,
          })
        );
      }
    );
  };
}

function deleteClassById(id) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("class_id", id);

  return (dispatch) => {
    return apiCaller("/api_class/class", "DELETE", urlencoded, null, true).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Giải tán lớp thành công",
            screen: teacherConstants.SCREEN_ADD_CLASS,
          })
        );
        // dispatch(getListClass());
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_CLASS,
          })
        );
      }
    );
  };
}

function getStudentOfClass(
  id,
  limit = configConstants.DEFAULT_LIMIT,
  offset = 0
) {
  return (dispatch) => {
    return teacherService
      .getStudentOfClass(id, limit, offset)
      .then((students) => {
        dispatch({
          type: teacherConstants.GET_STUDENT_OFF_CLASS,
          students,
        });
        return students;
      });
  };
}

function checkStudent(data) {
  return (dispatch) => {
    return apiCaller(
      "/api_user/code?user_code=" + data.user_code,
      "GET",
      {},
      null,
      false
    ).then(
      (addStudent) => {
        addStudent.user_data.user_code = data.user_code;
        dispatch({
          type: popupConstants.SHOW_FORM_POPUP,
          data: {
            showFormAddStudent: true,
          },
        });
        dispatch(alertActions.clear());
        dispatch({
          type: teacherConstants.ADD_STUDENT,
          data: addStudent,
        });
      },
      (error) => {
        if (error.toString() == "Server Error") {
          dispatch(
            alertActions.error({
              message: "Không tìm thấy tài khoản",
              screen: teacherConstants.SCREEN_ADD_STUDENT,
            })
          );
        } else {
          dispatch(
            alertActions.error({
              message: error.toString(),
              screen: teacherConstants.SCREEN_ADD_STUDENT,
            })
          );
        }
      }
    );
  };
}

function addStudent(data) {
  return (dispatch) => {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
    return apiCaller("/api_class/add_student", "POST", data, null, false).then(
      () => {
        dispatch(
          alertActions.success({
            message: teacherConstants.ADD_STUDENT_COMPLETE,
            screen: teacherConstants.SCREEN_ADD_STUDENT,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_STUDENT,
          })
        );
      }
    );
  };
}

function getRequestStudent(id) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_member_request?class_id=" + id,
      "GET",
      {},
      null,
      true
    ).then((requests) => {
      dispatch({
        type: teacherConstants.GET_STUDENT_REQUEST,
        requests: requests,
      });
    });
  };
}

function acceptRequestStudent(data, final = false) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("class_id", data.class_id);
  urlencoded.append("list_request_id", JSON.stringify(data.list_request_id));
  return (dispatch) => {
    return apiCaller(
      "/api_class/accept_member_request",
      "PUT",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Duyệt học sinh thành công",
            screen: teacherConstants.SCREEN_REVIEW_STUDENT,
          })
        );
        if (final) {
          setTimeout(() => {
            history.push("/teacher/class/view/" + data.class_id + "/student");
          }, 2000);
        } else {
          dispatch(getRequestStudent(data.class_id));
        }
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_REVIEW_STUDENT,
          })
        );
      }
    );
  };
}

function rejectRequestStudent(data, final = false) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("class_id", data.class_id);
  urlencoded.append("list_request_id", JSON.stringify(data.list_request_id));
  return (dispatch) => {
    return apiCaller(
      "/api_class/reject_member_request",
      "PUT",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Từ chối học sinh thành công",
            screen: teacherConstants.SCREEN_REVIEW_STUDENT,
          })
        );
        if (final) {
          setTimeout(() => {
            history.push("/teacher/class/view/" + data.class_id + "/student");
          }, 2000);
        } else {
          dispatch(getRequestStudent(data.class_id));
        }
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_REVIEW_STUDENT,
          })
        );
      }
    );
  };
}

function removeStudent(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("id", data.id);
  urlencoded.append("students", JSON.stringify([data.students]));
  return apiCaller("/api_class/students", "DELETE", urlencoded, null, false);
}

function removeStudentOffline(member_id) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("class_member_id", member_id);
  return apiCaller(
    "/api_class_offline/student",
    "DELETE",
    urlencoded,
    null,
    false
  );
}

function addCurriculum(data) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_curriculum",
      "POST",
      data,
      null,
      true
    ).then(() => {
      dispatch(
        alertActions.success({
          message: "Thêm giáo trình thành công",
          screen: curriculumConstants.ADD_CURRICULUM,
        })
      );
    });
  };
}

function editCurriculum(data) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_curriculum_edit",
      "PUT",
      data,
      null,
      true
    ).then(() => {
      dispatch(
        alertActions.success({
          message: "Thay giáo trình thành công",
          screen: curriculumConstants.ADD_CURRICULUM,
        })
      );
    });
  };
}

function removeCurriculum(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("curriculum_id", data.curriculum_id);
  urlencoded.append("class_id", data.class_id);
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_curriculum",
      "DELETE",
      urlencoded,
      null,
      true
    ).then(() => {
      dispatch(
        alertActions.success({
          message: "Xoá giáo trình thành công",
          screen: curriculumConstants.SCREEN_DETAIL_CURRICULUM,
        })
      );
      dispatch({
        type: curriculumConstants.CLEAR_DETAIL_CURRICULUM,
      });
      dispatch(getDetailClass(data.class_id));
    });
  };
}

function getCurriculum(type, exercise = false) {
  return (dispatch) => {
    return apiCaller(
      "/api_curriculum/courses_name?type=" + type,
      "GET",
      {},
      null,
      true
    ).then((curriculums) => {
      let type = exercise
        ? curriculumConstants.GET_LIST_CURRICULUM_EXERCISE
        : curriculumConstants.GET_LIST_CURRICULUM;
      dispatch({
        type,
        curriculums: curriculums.courses,
      });
    });
  };
}

function getCurriculumV2(type) {
  return apiCaller(
    "/api_curriculum/courses_name?type=" + type,
    "GET",
    {},
    null,
    true,
    configConstants.API_URL_SETEST,
    false
  ).then((curriculums) => {
    return curriculums.courses;
  });
}

function getCurriculumPersonal(type, exercise = false) {
  return apiCaller(
    "/api_curriculum/courses_name?type=" + type,
    "GET",
    {},
    null,
    true
  ).then((curriculums) => {
    return curriculums.courses;
  });
}

function getDetailCurriculumOfClass(curriculum_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_curriculum?curriculum_id=" + curriculum_id,
      "GET",
      {},
      null,
      true
    ).then(
      (curriculum) => {
        // console.log(curriculum);
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM_CLASS,
          curriculum: curriculum.data,
        });
      },
      () => {
        dispatch({
          type: curriculumConstants.CLEAR_DETAIL_CURRICULUM_CLASS,
        });
      }
    );
  };
}

function getDetailCurriculumById(id) {
  return (dispatch) => {
    return apiCaller(
      "/api_curriculum/course?id=" + id,
      "GET",
      {},
      null,
      true
    ).then((curriculum) => {
      dispatch({
        type: curriculumConstants.GET_DETAIL_CURRICULUM,
        curriculum,
      });
    });
  };
}

function getCurriculumFavorite(user_id) {
  return (dispatch) => {
    dispatch({
      type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE_START,
    });
    return apiCaller(
      "/api_wish_list/my_wish_list?user_id=" + user_id,
      "GET",
      {},
      null,
      true
    ).then((curriculums) => {
      dispatch({
        type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE,
        curriculums,
      });
    });
  };
}

function getCurriculumFavoriteV2(user_id, limit, offset) {
  return apiCaller(
    "/api_wish_list/my_wish_list?user_id=" +
      user_id +
      `&limit=${limit}&offset=${offset}`,
    "GET",
    {},
    null,
    true
  ).then((curriculums) => {
    return curriculums;
  });
}

function filterSkill(
  filter,
  class_id,
  limit,
  offset,
  filterCurriculum,
  listDataPrev,
  fromPage,
  assignAndUserID
) {
  return (dispatch) => {
    return apiCaller(
      (
        `/api_curriculum/search_lesson?${
          filterCurriculum == "filterCurriculum"
            ? `limit=${limit}&offset=${offset}&`
            : ""
        }topic=` +
        filter.topic +
        "&skill=" +
        JSON.stringify(filter.skill) +
        "&grade_id=" +
        JSON.stringify(filter.grade_id) +
        "&level=" +
        JSON.stringify(filter.level)
      ).toLowerCase(),
      "GET",
      {},
      null,
      true
    ).then((data) => {
      if (filterCurriculum == "filterCurriculum") {
        if (!hasDuplicates(data.list_data_result, listDataPrev)) {
          dispatch({
            type: curriculumConstants.ADD_PARAM_FILTER_CURRICULUM,
            param: filter,
          });
          dispatch({
            type: curriculumConstants.GET_FILTER_CURRICULUM,
            data: data.list_data_result.concat(...listDataPrev),
          });
          dispatch({
            type: popupConstants.CLEAR_ALL_POPUP,
          });
          if (class_id) {
            // history.push("/teacher/curriculum/filter/" + class_id);
            history.replace("/teacher/curriculum/filter/" + class_id);
          } else {
            history.replace(
              `/teacher/curriculum/filter?${
                !isEmpty(fromPage) ? `page=${fromPage}` : ""
              }`,
              {
                curriculum_sunday: true,
              }
            );
          }
        }
      } else {
        dispatch({
          type: curriculumConstants.ADD_PARAM_FILTER_CURRICULUM,
          param: filter,
        });
        dispatch({
          type: curriculumConstants.GET_FILTER_CURRICULUM,
          data: data.list_data_result,
        });
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        if (class_id) {
          // history.push("/teacher/curriculum/filter/" + class_id);
          history.replace("/teacher/curriculum/filter/" + class_id);
        } else {
          history.replace(
            !isEmpty(assignAndUserID)
              ? `/teacher/curriculum/filter?${
                  !isEmpty(assignAndUserID)
                    ? `assign=${assignAndUserID.assign}&user_id=${assignAndUserID.user_id}`
                    : ""
                }`
              : `/teacher/curriculum/filter?${
                  !isEmpty(fromPage) ? `page=${fromPage}` : ""
                }`,
            {
              curriculum_sunday: true,
            }
          );
        }
      }

      return data;
    });
  };
}

function getHistoryExercise(
  class_id,
  param = { skill: [], level: [], start_time: "", end_time: "" },
  type,
  limitDefault,
  offset,
  prevState
) {
  let uri =
    type == "class"
      ? `/api_class/class_homework_v2?&limit=${limitDefault}&offset=${offset}&class_id=${class_id}&type=class`
      : type == "class_mark"
      ? `/api_class/class_homework_v2?&limit=${limitDefault}&offset=${offset}&class_id=` +
        class_id +
        "&type=class_mark"
      : `/api_class/class_homework_v2?&type=class&limit=${limitDefault}&offset=${offset}&class_id=` +
        class_id;
  if (param.skill?.length) {
    param.skill.forEach((skill) => {
      uri += "&skill[]=" + skill.toLocaleLowerCase();
    });
  }
  if (param.level?.length) {
    param.level.forEach((skill) => {
      uri += "&level[]=" + skill.toLocaleLowerCase();
    });
  }
  if (param.start_time) {
    uri += "&start_time=" + JSON.stringify(param.start_time);
  }
  if (param.end_time) {
    uri += "&end_time=" + JSON.stringify(param.end_time);
  }
  return (dispatch) => {
    return apiCaller(uri, "GET", {}, null, true).then((histories) => {
      if (!hasDuplicates(prevState, histories.data)) {
        dispatch({
          type: teacherConstants.GET_LIST_HISTORY_EXERCISE,
          histories: histories.data.concat(...prevState),
        });
        dispatch({
          type: teacherConstants.GET_LIST_HOMEWORK,
          homeworks: histories.data.concat(...prevState),
        });
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
      }
      return histories;
    });
  };
}

function getHistoryExerciseWithoutClass(
  param = { skill: [] },
  filter_time = false
) {
  let uri = "/api_class/class_homework_v2?limit=999&offset=0";
  param.skill.forEach((skill) => {
    uri += "&skill[]=" + skill.toLocaleLowerCase();
  });
  if (filter_time) {
    uri +=
      "&start_time=" +
      JSON.stringify(param.start_time) +
      "&end_time=" +
      JSON.stringify(param.end_time);
  }
  return (dispatch) => {
    return apiCaller(uri, "GET", {}, null, true).then((histories) => {
      dispatch({
        type: teacherConstants.GET_LIST_HISTORY_EXERCISE,
        histories: histories.data,
      });
      dispatch({
        type: popupConstants.CLEAR_ALL_POPUP,
      });
    });
  };
}

function getDetailExercise(class_id, exercise_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_homework_detail?class_id=" +
        class_id +
        "&exercise_id=" +
        exercise_id,
      "GET",
      {},
      null,
      true
    ).then((histories) => {
      dispatch({
        type: teacherConstants.GET_DETAIL_HISTORY_EXERCISE,
        histories: histories,
      });
    });
  };
}

function getDetailExerciseWithoutClass(exercise_id, skill, curriculum_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_wish_list/wish_list_history?exercise_id=" +
        exercise_id +
        (skill ? "&lesson_type=exam" : "") +
        `&curriculum_id=${curriculum_id}`,
      "GET",
      {},
      null,
      true
    ).then((histories) => {
      dispatch({
        type: teacherConstants.GET_DETAIL_HISTORY_EXERCISE,
        histories: histories,
      });
    });
  };
}

function udpateExercise(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("class_id", data.class_id);
  urlencoded.append("start_time", data.start_time);
  urlencoded.append("end_time", data.end_time);
  urlencoded.append("before_start_time", data.before_start_time);
  urlencoded.append("note", data.note);
  urlencoded.append("exercise_id", data.exercise_id);
  urlencoded.append("remind_before", data.remind_before);
  urlencoded.append("students", JSON.stringify(data.students));
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_homework",
      "PUT",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Cập nhật bài thành công",
            screen: teacherConstants.SCREEN_ADD_STUDENT_EXERCISE,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_STUDENT_EXERCISE,
          })
        );
      }
    );
  };
}

function getProfile(id) {
  return (dispatch) => {
    return apiCaller("/api_user/user?id=" + id, "GET", {}, null, true).then(
      (data) => {
        localStorage.setItem("info_header_user", JSON.stringify(data.data));
        dispatch({
          type: userConstants.GET_PROFILE,
          data: data.data,
          base_url: data.base_url,
        });
      }
    );
  };
}

function getProfileV2(id) {
  return (dispatch) => {
    return apiCaller(
      "/api_profile/profile?id=" + id,
      "GET",
      {},
      null,
      true
    ).then((data) => {
      // console.log(data);

      const convertData = {
        ...data.user,
        // add other objects to the user object
        parent_info: data?.parent_info,
        parent_exercise: data?.parent_exercise,
        teacher_exercise: data?.teacher_exercise,
        class_exercise: data?.class_exercise,
        package: data?.package,
      };

      dispatch({
        type: userConstants.GET_PROFILE,
        data: convertData,
        base_url: data.base_url,
      });

      let authentication = JSON.parse(localStorage.getItem("authentication"));
      authentication?.isLogin &&
        dispatch({
          type: userConstants.LOGIN,
          user: {
            ...authentication,
            curriculum_default: data.user?.curriculum_default,
            fullname: data.user?.fullname,
            avatar: configConstants.BASE_URL + data.user?.avatar,
            birthday: data.user?.birthday,
            school: data.user?.school,
            class: data.user?.class,
            phone: data.user?.phone,
            gender: data.user?.gender,
            grade_id: data.user?.grade_id,
            total_diamond: data.user?.total_diamond,
            package: data?.package,
          },
        });
    });
  };
}

function getProfileNews(id) {
  return (dispatch) => {
    return apiCaller(
      "/api_profile/profile?id=" + id,
      "GET",
      {},
      null,
      true
    ).then((data) => {
      // console.log(data);
      dispatch({
        type: userConstants.GET_PROFILE_NEWS,
        data: data.user,
        base_url: data.base_url,
      });
    });
  };
}

function updateProfile(data) {
  return (dispatch) => {
    return apiCaller("/api_user/user", "PUT", data, null, false).then(
      () => {
        dispatch(
          alertActions.success({
            message: userConstants.MESSAGE_UPDATE_PROFILE_COMPLETE,
            screen: userConstants.SCREEN_UPDATE_PROFILE,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: userConstants.SCREEN_UPDATE_PROFILE,
          })
        );
      }
    );
  };
}

function getFileExercise(param, clear_popup = true) {
  return (dispatch) => {
    return apiCaller(
      "/api_resources/my_resources?type=" +
        JSON.stringify(param.type) +
        "&skill=" +
        JSON.stringify(param.skill) +
        "&grade_id=" +
        JSON.stringify(param.grade_id),
      "GET",
      {},
      null,
      true
    ).then((files) => {
      dispatch({
        type: teacherConstants.ADD_DATA_FILE,
        data: files.resources,
      });
      if (clear_popup) {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
      }
    });
  };
}

function addExercise(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("students", JSON.stringify(data.students));
  urlencoded.append("data_exercise", JSON.stringify(data.data_exercise));
  urlencoded.append("before_start_time", data.before_start_time);
  urlencoded.append("class_id", data.class_id);
  urlencoded.append("note", data.note);
  urlencoded.append("remind_before", data.remind_before);
  urlencoded.append("end_time", data.end_time);
  return (dispatch) => {
    return apiCaller(
      "/api_class/give_homework",
      "POST",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Giao bài thành công",
            screen: teacherConstants.SCREEN_ADD_EXERCISE,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_EXERCISE,
          })
        );
      }
    );
  };
}

function getListHomeWork(class_id, type, limit, offset, listHomeWork) {
  // const url = `/api_class/class_homework_v2?class_id=${class_id}&type=${type}&limit=${limit}&offset=${offset}`;
  const url = `/api_class/class_homework_v2?class_id=${class_id}&type=${type}`;
  return (dispatch) => {
    return apiCaller(url, "GET", {}, null, true).then((homeworks) => {
      if (!hasDuplicates(homeworks.data, listHomeWork)) {
        dispatch({
          type: teacherConstants.GET_LIST_HOMEWORK,
          homeworks: homeworks.data,
        });
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
      }
      return homeworks;
    });
  };
}

function getDetailHomeWork(class_id, exercise_id, library, type) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/home_work_detail?class_id=" +
        class_id +
        "&exercise_id=" +
        exercise_id +
        "&library=" +
        library +
        "&type=" +
        type,
      "GET",
      {},
      null,
      true
    ).then((homeworks) => {
      dispatch({
        type: teacherConstants.GET_DETAIL_HOMEWORK,
        homeworks: homeworks,
      });
      dispatch({
        type: popupConstants.CLEAR_ALL_POPUP,
      });
    });
  };
}

function getDetailHomeWorkExercise(user_exercise_id, library, exercise_type) {
  return (dispatch) => {
    return apiCaller(
      "/student/api_homework/homework_detail?user_exercise_id=" +
        user_exercise_id +
        "&library=" +
        library +
        "&exercise_type=" +
        exercise_type,
      "GET",
      {},
      null,
      true
    ).then((exercises) => {
      dispatch({
        type: teacherConstants.GET_DETAIL_HOMEWORK_EXERCISE,
        exercises: exercises,
      });
    });
  };
}

function updatelHomeWorkExercise(data, messageSuccess) {
  var urlencoded = new URLSearchParams();
  urlencoded.append(
    "json_criteria_score",
    JSON.stringify(data.json_criteria_score)
  );
  urlencoded.append("user_exercise_id", data.user_exercise_id);
  urlencoded.append("score", data.score);
  urlencoded.append("comment", data.comment);
  urlencoded.append(
    "json_writing_check",
    JSON.stringify(data.json_writing_check)
  );
  urlencoded.append("library", data.library);
  urlencoded.append("exercise_type", data.exercise_type);
  return (dispatch) => {
    return apiCaller(
      "/api_class/save_mark_exercise",
      "PUT",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: messageSuccess || "Cập nhật bài thành công",
            screen: teacherConstants.SCREEN_EDIT_HOME_WORK,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_EDIT_HOME_WORK,
          })
        );
      }
    );
  };
}

function callAIHomeWork(
  content,
  user_received_id = "",
  library,
  user_exercise_id,
  class_id
) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/ai_check_writing",
      "POST",
      {
        content,
        user_received_id,
        library,
        user_exercise_id,
        class_id,
      },
      null,
      true
    ).then((data) => {
      dispatch({
        type: teacherConstants.CALL_AI_HOMEWORK,
        data: data.data_check,
      });
    });
  };
}

function getStudentRanking(type, class_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/ranking?type=" + type + "&class_id=" + class_id,
      "GET",
      {},
      null,
      true
    ).then((data) => {
      dispatch({
        type: teacherConstants.GET_STUDENT_RANKING,
        data: data.data,
        base_url: data.base_url,
      });
    });
  };
}

function getClassRanking(id) {
  return (dispatch) => {
    return apiCaller("/api_class/ranking?id=" + id, "GET", {}, null, true).then(
      (data) => {
        dispatch({
          type: teacherConstants.GET_CLASS_RANKING,
          data: data.data,
        });
      }
    );
  };
}

// Roll Up
function createRollUp(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("list_student", JSON.stringify(data.list_student));
  urlencoded.append("class_id", data.class_id);
  urlencoded.append("send_to_parent", data.send_to_parent);
  urlencoded.append("date", data.date);
  urlencoded.append("send_content", data.send_content);
  urlencoded.append("total_student", data.total_student);
  urlencoded.append("number_absence", data.number_absence);
  urlencoded.append("general_comment", data.general_comment);
  return (dispatch) => {
    return apiCaller(
      "/api_class_offline/roll_up",
      "POST",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Tạo điểm danh thành công",
            screen: teacherConstants.SCREEN_ADD_ROLL_UP,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_ROLL_UP,
          })
        );
      }
    );
  };
}

function updateRollUp(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("list_student", JSON.stringify(data.list_student));
  urlencoded.append("class_id", data.class_id);
  urlencoded.append("send_to_parent", data.send_to_parent);
  urlencoded.append("roll_up_id", data.roll_up_id);
  urlencoded.append("total_student", data.total_student);
  urlencoded.append("number_absence", data.number_absence);
  urlencoded.append("general_comment", data.general_comment);
  return (dispatch) => {
    return apiCaller(
      "/api_class_offline/roll_up",
      "PUT",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Cập nhật điểm danh thành công",
            screen: teacherConstants.SCREEN_ADD_ROLL_UP,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_ROLL_UP,
          })
        );
      }
    );
  };
}

function getExamcardOffline(class_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_class_offline/class_exam_card?class_id=" + class_id,
      "GET",
      {},
      null,
      true
    ).then((exam_card) => {
      dispatch({
        type: teacherConstants.GET_EXAM_CARD,
        exam_card,
      });
    });
  };
}

function createExamCard(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("exam_mark_detail", JSON.stringify(data.exam_mark_detail));
  urlencoded.append("class_id", data.class_id);
  urlencoded.append("exam_name", data.exam_name);
  urlencoded.append("date_test", data.date_test);
  urlencoded.append("is_send_parent", data.is_send_parent);
  urlencoded.append("type", data.type);
  urlencoded.append("score_percent", data.score_percent);
  urlencoded.append("semester", data.semester);
  urlencoded.append("send_content", data.send_content);
  return (dispatch) => {
    return apiCaller(
      "/api_class_offline/exam_card",
      "POST",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Tạo phiếu điểm thành công",
            screen: teacherConstants.SCREEN_ADD_EXAM_CARD,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_EXAM_CARD,
          })
        );
      }
    );
  };
}

function updateExamCard(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("exam_mark_detail", JSON.stringify(data.exam_mark_detail));
  urlencoded.append("card_id", data.card_id);
  urlencoded.append("exam_name", data.exam_name);
  urlencoded.append("date_test", data.date_test);
  urlencoded.append("is_send_parent", data.is_send_parent);
  urlencoded.append("type", data.type);
  urlencoded.append("score_percent", data.score_percent);
  urlencoded.append("semester", data.semester);
  urlencoded.append("send_content", data.send_content);
  return (dispatch) => {
    return apiCaller(
      "/api_class_offline/exam_card",
      "PUT",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Cập nhật phiếu điểm thành công",
            screen: teacherConstants.SCREEN_ADD_EXAM_CARD,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_ADD_EXAM_CARD,
          })
        );
      }
    );
  };
}

function deleteFileExercise(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("id", data.id);
  return (dispatch) => {
    return apiCaller(
      "/api_resources/resource",
      "DELETE",
      urlencoded,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Xóa hướng dẫn học tập thành công.",
            screen: teacherConstants.SCREEN_LIST_FILE,
          })
        );
        dispatch(getFileExercise(data.param));
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_LIST_FILE,
          })
        );
      }
    );
  };
}

function addFileResource(data, param) {
  return (dispatch) => {
    return apiCaller(
      "/api_resources/upload_guide",
      "POST",
      data,
      null,
      true
    ).then(
      () => {
        dispatch({
          type: popupConstants.SHOW_FORM_POPUP,
          data: {
            addFileCompleteResource: true,
          },
        });
        dispatch(getFileExercise(param, false));
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_LIST_FILE,
          })
        );
      }
    );
  };
}

function getReportClass(class_id, from_date, to_date) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/report_overview_class?class_id=" +
        class_id +
        "&from_date=" +
        from_date +
        "&to_date=" +
        to_date,
      "GET",
      {},
      null,
      true
    ).then((reports) => {
      dispatch({
        type: teacherConstants.GET_LIST_REPORT_CLASS,
        reports,
      });
    });
  };
}

function getReportByStudentId(class_id, student_id, from_date, date_time) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/report_overview_student?student_id=" +
        student_id +
        "&class_id=" +
        class_id +
        "&from_date=" +
        from_date +
        "&to_date=" +
        date_time +
        "&date_time=" +
        date_time,
      "GET",
      {},
      null,
      true
    ).then((students) => {
      dispatch({
        type: teacherConstants.GET_REPORT_BY_STUDENT_ID,
        students,
      });
    });
  };
}

function getReportByStudentIdByDateTime(
  class_id,
  student_id,
  from_date,
  date_time
) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/report_overview_student?student_id=" +
        student_id +
        "&class_id=" +
        class_id +
        "&date_time=" +
        date_time,
      "GET",
      {},
      null,
      true
    ).then((students) => {
      dispatch({
        type: teacherConstants.GET_REPORT_BY_STUDENT_ID,
        students,
      });
    });
  };
}

function getLogLearningByStudentId(student_id, class_id, from_time) {
  if (!from_time) {
    from_time = moment().format("YYYY-MM-DD");
  }
  return (dispatch) => {
    return apiCaller(
      "/api_student/student_log_learning?student_id=" +
        student_id +
        "&class_id=" +
        class_id +
        "&from_time=" +
        from_time,
      "GET",
      {},
      null,
      true
    ).then((log_learning) => {
      dispatch({
        type: teacherConstants.GET_LOG_LEARNING_BY_STUDENT_ID,
        log_learning,
      });
      dispatch({
        type: scheduleConstants.SET_SELECT_DATE,
        time: from_time,
      });
    });
  };
}

function resetPassword(data) {
  return (dispatch) => {
    return apiCaller("/api_user/user/", "PUT", data, null, false).then(
      () => {
        dispatch(
          alertActions.success({
            message: userConstants.MESSAGE_CHANGE_PASSWORD_COMPLETE,
            screen: userConstants.SCREEN_RESET_PASSWORD,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: userConstants.SCREEN_RESET_PASSWORD,
          })
        );
      }
    );
  };
}

function getListCriteria(exercise_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/load_exercise_criteria?exercise_id=" + exercise_id,
      "GET",
      {},
      null,
      false
    ).then((criteria) => {
      dispatch({
        type: teacherConstants.GET_LIST_CRITERIA,
        criteria: criteria.data_criteria,
      });
    });
  };
}

function updateCriteria(json_criteria, exercise_id, exerciseId) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("json_criteria", JSON.stringify(json_criteria));
  urlencoded.append("exercise_id", exercise_id);
  return (dispatch) => {
    return apiCaller(
      "/api_class/writing_criteria",
      "PUT",
      urlencoded,
      null,
      false
    ).then(() => {
      dispatch(getListCriteria(exercise_id === 0 ? null : exercise_id));
    });
  };
}

function remindStudent(data) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("msg", data.msg);
  urlencoded.append(
    "list_user_exercise_id",
    JSON.stringify(data.list_user_exercise_id)
  );

  return (dispatch) => {
    return apiCaller(
      "/api_inbox/remind_all",
      "POST",
      urlencoded,
      null,
      false
    ).then(
      () => {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        dispatch(getDetailExercise(data.class_id, data.exercise_id));
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_REMIND_STUDENT,
          })
        );
      }
    );
  };
}

function addWish(data) {
  return (dispatch) => {
    return apiCaller(
      "/api_wish_list/my_wish_list",
      "POST",
      data,
      null,
      false
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Thêm bài tập vào danh sách yêu thích",
            screen: teacherConstants.ADD_WISH,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.ADD_WISH,
          })
        );
      }
    );
  };
}

function removeWish(data) {
  var urlencoded = new URLSearchParams();
  // urlencoded.append("user_wish_id", data.user_wish_id);
  urlencoded.append("exercise_id", data.exercise_id);

  return (dispatch) => {
    return apiCaller(
      "/api_wish_list/my_wish_list",
      "DELETE",
      urlencoded,
      null,
      false
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Xóa bài tập khỏi danh sách yêu thích",
            screen: teacherConstants.ADD_WISH,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.ADD_WISH,
          })
        );
      }
    );
  };
}

function getRollUpById(id = "", callback = (res) => {}) {
  return (dispatch) => {
    return apiCaller(
      "/api_class_offline/roll_up?id=" + id,
      "GET",
      {},
      null,
      true
    ).then((res) => {
      if (callback) {
        callback(res);
      }
    });
  };
}

function getLogLearningByDate(date, student_id, class_id) {
  return (dispatch) => {
    return apiCaller(
      `/api_student/log_learning_by_date?date=${date}&student_id=${student_id}&class_id=${class_id}`,
      "GET",
      {},
      null,
      true
    ).then((learnings) => {
      dispatch({
        type: teacherConstants.GET_LOG_LEARNING_BY_DATE,
        learnings,
      });
    });
  };
}
