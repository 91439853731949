import axios from "axios";
import { configConstants } from "../_constants";
import { handleResponse } from "../_helpers";
import $ from "jquery";

class APIBase {
  static apiBaseCaller(method, url, data, isLoading) {
    return new Promise((resolve, reject) => {
      $(".loading").removeClass("hide");
      let headers = {
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "",
      };

      if (localStorage.getItem("access_token")) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        headers["Authorization"] = `Bearer ${access_token}`;
      }

      axios({
        method,
        url,
        headers: headers,
        data,
      })
        .then((response) => {
          resolve(response.data);
          $(".loading").addClass("hide");
        })
        .catch((error) => {
          $(".loading").removeClass("hide");
          setTimeout(() => {
            $(".loading").addClass("hide");
          }, 2000);
          reject(error.response.data);
          if (error.message == "Network Error") {
            alert("Vui lòng kiểm tra kết nối Internet");
          }

          console.log("error2 ====", error);
        });
    });
  }

  static apiBaseNoLoading(method, url, data) {
    return new Promise((resolve, reject) => {
      let headers = {
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "",
      };

      if (localStorage.getItem("access_token")) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        headers["Authorization"] = `Bearer ${access_token}`;
      }

      axios({
        method,
        url,
        headers: headers,
        data,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
          if (error.message == "Network Error") {
            alert("Vui lòng kiểm tra kết nối Internet");
          }
        });
    });
  }

  static apiBaseCallerControlLoading(method, url, data, isLoading) {
    return new Promise((resolve, reject) => {
      isLoading && $(".loading").removeClass("hide");
      let headers = {
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "",
      };

      if (localStorage.getItem("access_token")) {
        let access_token = JSON.parse(localStorage.getItem("access_token"));

        headers["Authorization"] = `Bearer ${access_token}`;
      }

      axios({
        method,
        url,
        headers: headers,
        data,
      })
        .then((response) => {
          resolve(response.data);
          isLoading && $(".loading").addClass("hide");
        })
        .catch((error) => {
          isLoading && $(".loading").removeClass("hide");
          setTimeout(() => {
            isLoading && $(".loading").addClass("hide");
          }, 3000);
          reject(error);
          if (error.message == "Network Error") {
            alert("Vui lòng kiểm tra kết nối Internet");
          }
        });
    });
  }

  static apiBaseCallerMsg(method, url, data, isLoading = true) {
    let headers = {
      // "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "",
    };

    if (localStorage.getItem("access_token")) {
      let access_token = JSON.parse(localStorage.getItem("access_token"));

      headers["Authorization"] = `Bearer ${access_token}`;
    }

    return axios({
      method,
      timeout: configConstants.API_TIMEOUT,
      url: url,
      data: data,
      headers: headers,
    })
      .then((res) => handleResponse(res, isLoading))
      .catch((error) => {
        if (isLoading) {
          $(".loading").addClass("hide");
        }
        if (error && error.message) {
          let msg = error.message;
          // if (
          //   error.response.data.msg ||
          //   error.response.data.error ||
          //   error.phone
          // ) {
          //   msg = error.response.data.msg
          //     ? error.response.data.msg
          //     : error.response.data.error;
          // }
          return Promise.reject(msg);
        } else {
          if (
            typeof error === "string" ||
            error instanceof String ||
            typeof error == "object"
          ) {
            return Promise.reject(error);
          } else {
            return Promise.reject("Server Error 123123");
          }
        }
      });
  }
}

export default APIBase;
