import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SideBar } from './../Admin/SideBar';
import { useSelector } from 'react-redux';

export const RouteRedirectToLogin = ({ component: Component, noSideBar: noSideBar, containerClassName ,...rest }) => {
    const sideBarRootLessness = useSelector(state => state.rootlessness.sideBarRootLessness)
    return (
        <Route exact={true} {...rest} render={props => {
            if (rest.authentication.isLogin) {
                if (rest.authentication.role === rest.prefix) {
                    if(containerClassName) {
                        return (
                            <div className={containerClassName}>
                                {noSideBar && sideBarRootLessness ? null : <SideBar />}
                                <Component {...props} />
                            </div>
                        )
                    }
                    return (
                        <Fragment>
                            {noSideBar && sideBarRootLessness ? null : <SideBar />}
                            <Component {...props} />
                        </Fragment>

                    )
                } else {
                    return <Redirect to={{ pathname: rest.authentication.role, state: { from: props.location } }} />
                }

            } else {
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
        }} />
    );
};

RouteRedirectToLogin.propTypes = {
    location: PropTypes.any,
    component: PropTypes.any,
};
