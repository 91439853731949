import { userConstants } from "./../../_constants";
var initialState = {
    codeSent: false,
    timeRemaining: 30,
    registerStatus: false,
    dataAccountRegister: {},
    registerWithOtherZalo: true,
    loginWhenNotVerify: false,
};

export const setTimeRemaining = (time) => ({
    type: userConstants.TIMEREMAINING,
    payload: time,
});

const authenticationTeacherZalo = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.CODE_SENT_ON:
      return {
        ...state,
        codeSent: true
      }
    case userConstants.CODE_SENT_OFF:
      return {
        ...state,
        codeSent: false
      }
    case userConstants.TIMEREMAINING:
        return {
            ...state,
            timeRemaining: action.payload
          }
    case userConstants.REGISTER_STATUS:
        return {
            ...state,
            registerStatus: true
          }
    case userConstants.RESET_REGISTER_STATUS:
        return {
            ...state,
            registerStatus: false
          }
    case userConstants.SAVE_DATA_REGISTER:
        return {
            ...state,
            dataAccountRegister: action.payload
          }
    case userConstants.REGISTER_WITH_OTHER_ZALO:
      return {
        ...state,
        registerWithOtherZalo: false
      }
    case userConstants.LOGIN_WHEN_NOT_VERIFY:
      return {
        ...state,
        loginWhenNotVerify: true
      }
    case userConstants.RESET_LOGIN_WHEN_NOT_VERIFY:
      return {
        ...state,
        loginWhenNotVerify: false
      }
    default:
      return state;
  }
};

export { authenticationTeacherZalo };
