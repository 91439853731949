import React, { useState } from 'react';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';
import { teacherConstants, popupConstants } from './../../_constants';
import { teacherActions } from './../../_actions';
import { Alert } from './../../_components/Alert';



function PopUpRemind(props) {

    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    let { width } = props;

    const [inputs, setInputs] = useState({
        content: ''
    });


    function validateParam() {
        return (inputs.content) ? true : false;
    }

    function close() {
        dispatch({
            'type': popupConstants.CLEAR_ALL_POPUP,
        })
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function hanldeForm() {
        setSubmitted(true);
        if (validateParam()) {
            let list_user_exercise_id = props.listStudentIdAll;
            let dataRemind = {
                list_user_exercise_id,
                msg: inputs.content,
                exercise_id: props.exercise_id,
                class_id: props.class_id,
            }
            dispatch(teacherActions.remindStudent(dataRemind));
        }
    }

    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ width: width }}>
                {alert.message && alert.screen === teacherConstants.SCREEN_REMIND_STUDENT && <Alert alert={alert} notShowComplete={true} />}
                <div className={'message-body' + (submitted && !inputs.content ? ' err' : '')}>
                    <textarea className="custom-input" name="content" id="message" rows="3" placeholder="Nhập nội dung nhắc nhở" onChange={handleChange} defaultValue={inputs.content}></textarea>
                </div>
                <div className="flex-m btn-popup-gr">
                    <button className="btn-default btn-custom-width mr-10" onClick={close}>Đóng</button>
                    <button className={'btn-bg-blue btn-default btn-custom-width' + (!validateParam() ? ' btn-disable' : '')} onClick={hanldeForm}>Gửi</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpRemind };