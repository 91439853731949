import { licenseConstants } from './../../_constants';
var initialState = {
    listCurentLicenses: [],
    listHistoryLicenses: [],
};

const licenses = (state = initialState, action) => {
    switch (action.type) {
        case licenseConstants.GET_CURRENT_LICENSE:
            return {
                ...state,
                listCurentLicenses: action.listCurentLicenses
            };
        case licenseConstants.GET_HISTORY_LICENSE:
            return {
                ...state,
                listHistoryLicenses: action.listHistoryLicenses
            };
        default: return state;
    }
};

export { licenses };