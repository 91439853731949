import { userConstants } from "./../../_constants";
var initialState = {
  isLogin: false,
};
if (localStorage.getItem("authentication")) {
  initialState = JSON.parse(localStorage.getItem("authentication"));
}
const authentication = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN:
      return action.user;
    case userConstants.LOGOUT:
      return {
        isLogin: false,
      };
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { authentication };
