import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentActions } from "./../../_actions";
import moment from "moment";
import { popupConstants } from "./../../_constants";
import { userConstants } from "../../_constants";

import "./style.css";

function PopUpApplyClass(props) {
  let { width } = props;
  const dispatch = useDispatch();
  const classDetail = useSelector((state) => state.classes.detail);

  const authentication = useSelector((state) => state.authentication);

  function handleSubmit() {
    if (authentication.role === userConstants.ROLE_STUDENT) {
      dispatch(
        studentActions.applyForClass({
          class_code: classDetail.class_data.class_code,
        })
      );
    }
  }
  function close() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }
  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      {classDetail.class_data && (
        <div
          className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
          style={{ width: width }}
        >
          <div className="info-img avta">
            <img
              src="/assets/images/student/popup_add_hs.png"
              alt="popup_add_hs"
            />
          </div>
          <h2 className="text-center popup-title bold title-student-pop">
            {classDetail.class_data.class_name}
          </h2>
          <div className="flex-m info-gr info-gr-popup">
            <div>
              <span className="class-online">
                {classDetail.class_data.type}
              </span>
            </div>
          </div>
          <div className="flex-m info-gr info-gr-popup">
            <div className="info-img avta">
              <img
                src="/assets/images/student/ico_teacher.png"
                alt="ico_teacher"
              />
            </div>
            <div className="flex-1 info-con">
              <p>{classDetail.class_data.teacher_name}</p>
            </div>
          </div>
          <div className="flex-m info-gr info-gr-popup">
            <div className="info-img">
              <img
                src="/assets/images/teacher/ico_school.png"
                alt="ico_school"
              />
            </div>
            <div className="flex-1 info-con">
              <p>{classDetail.class_data.organization_name}</p>
            </div>
          </div>
          {/* <div className="flex-m info-gr info-gr-popup">
                    <div className="info-img">
                        <img src="/assets/images/teacher/ico_clock.png" alt="ico_clock" />
                    </div>
                    <div className="flex-1 info-con">
                        <p>{moment(classDetail.class_data.start_time).format('DD/MM/YYYY')} - {moment(classDetail.class_data.end_time).format('DD/MM/YYYY')}</p>
                    </div>
                </div> */}
          <div className="flex-m info-gr info-gr-popup">
            <div className="info-img">
              <img src="/assets/images/teacher/ico_users.png" alt="ico_users" />
            </div>
            <div className="flex-1 info-con">
              <p>{classDetail.class_data.number_student}</p>
            </div>
          </div>

          <div className="flex-m btn-popup-gr justify-content-center">
            <button
              className="btn-default btn-dkvl __btn-cancel"
              onClick={close}
            >
              Huỷ
            </button>
            <button
              className="btn-bg-blue btn-default btn-dkvl __btn-register"
              onClick={handleSubmit}
            >
              Đăng ký
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export { PopUpApplyClass };
