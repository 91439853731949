export const curriculumConstants = {
  GET_LIST_CURRICULUM_FAVORITE_START: "GET_LIST_CURRICULUM_FAVORITE_START",
  GET_LIST_CURRICULUM: "GET_LIST_CURRICULUM",
  ADD_CURRICULUM: "ADD_CURRICULUM",
  GET_DETAIL_CURRICULUM_CLASS: "GET_DETAIL_CURRICULUM_CLASS",
  GET_DETAIL_CURRICULUM: "GET_DETAIL_CURRICULUM",
  SCREEN_DETAIL_CURRICULUM: "SCREEN_DETAIL_CURRICULUM",
  CLEAR_DETAIL_CURRICULUM: "CLEAR_DETAIL_CURRICULUM",
  CLEAR_DETAIL_CURRICULUM_CLASS: "CLEAR_DETAIL_CURRICULUM_CLASS",
  GET_LIST_CURRICULUM_FAVORITE: "GET_LIST_CURRICULUM_FAVORITE",
  GET_FILTER_CURRICULUM: "GET_FILTER_CURRICULUM",
  ADD_PARAM_FILTER_CURRICULUM: "ADD_PARAM_FILTER_CURRICULUM",
  CLEAR_PARAM_FILTER_CURRICULUM: "CLEAR_PARAM_FILTER_CURRICULUM",
  CHANGE_VIEW: "CHANGE_VIEW",
  GET_LIST_CURRICULUM_EXERCISE: "GET_LIST_CURRICULUM_EXERCISE",
  GET_DATA_MAP_CURRICULUM_STUDENT_START:
    "GET_DATA_MAP_CURRICULUM_STUDENT_START",
  GET_DATA_MAP_CURRICULUM_STUDENT: "GET_DATA_MAP_CURRICULUM_STUDENT",
  GET_DATA_GRID_CURRICULUM_STUDENT: "GET_DATA_GRID_CURRICULUM_STUDENT",
  GET_LESSON_CURRICULUM_STUDENT: "GET_LESSON_CURRICULUM_STUDENT",
  RESET_DETAIL: "RESET_DETAIL",
  FROM_PAGE_STATUS: "FROM_PAGE_STATUS",
  CLEAR_FROM_PAGE: "CLEAR_FROM_PAGE",
  LESSON_BY_SKILL_TEACHER: "LESSON_BY_SKILL_TEACHER",
  GET_LESSON_IN_SKILL: "GET_LESSON_IN_SKILL",
};
