import axios from "axios";
import { configConstants } from "../_constants";
import { history } from "./../_helpers";
import $ from "jquery";

const authDefault = {
  username: configConstants.AUTH_BASIC_USER_NAME,
  password: configConstants.AUTH_BASIC_PASSWORD,
};

export function apiCaller(
  endpoint,
  method = "GET",
  body = {},
  headers = null,
  clearToken = true,
  basUrl = configConstants.API_URL_SETEST,
  isLoading = true,
  isFullResponse = false,
) {
  if (headers === undefined || headers === null) {
    headers = {
      "X-API-KEY": configConstants.API_KEY,
    };
    if (localStorage.getItem("authentication")) {
      let authentication = JSON.parse(localStorage.getItem("authentication"));
      headers["jwt_token"] = authentication.jwt_token;
    }
  } else {
    if (localStorage.getItem("authentication")) {
      let authentication = JSON.parse(localStorage.getItem("authentication"));
      headers["jwt_token"] = authentication.jwt_token;
    }
    headers = {
      "X-API-KEY": configConstants.API_KEY,
      ...headers,
    }
  }
  if (isLoading) {
    $(".loading").removeClass("hide");
  }
  return axios({
    method,
    timeout: configConstants.API_TIMEOUT,
    url: `${basUrl}${endpoint}`,
    auth: {
      username: configConstants.AUTH_BASIC_USER_NAME,
      password: configConstants.AUTH_BASIC_PASSWORD,
    },
    data: body,
    headers: headers,
  })
    .then((res) => handleResponse(res, isLoading, isFullResponse))
    .catch((error) => {
      if (isLoading) {
        $(".loading").addClass("hide");
      }
      if (error && error.response) {
        if (clearToken && error.response.status === 401) {
          clearLocalStorage();
        } else {
          let msg = "Server Error";
          if (error.response.data.msg || error.response.data.error) {
            msg = error.response.data.msg
              ? error.response.data.msg
              : error.response.data.error;
          }
          return Promise.reject(msg);
        }
      } else {
        if (typeof error === "string" || error instanceof String) {
          return Promise.reject(error);
        } else {
          return Promise.reject("Server Error");
        }
      }
    });
}

function clearLocalStorage() {
  // localStorage.clear();
  localStorage.removeItem("authentication");
  localStorage.removeItem("access_token");
  history.push("/login");
}

export function handleResponse(response, isLoading, isFullResponse) {
  if (isLoading) {
    $(".loading").addClass("hide");
  }
  if(isFullResponse) {
    return response.data;
  }
  if (!response.data.status) {
    let msg = response.data.msg ? response.data.msg : response.data.error;
    return Promise.reject(msg);
  } else {
    return response.data;
  }
}

export async function apiRequest(url = "/", data = {}) {
  try {
    let headers = {
      "X-API-KEY": configConstants.API_KEY,
    };
    if (localStorage.getItem("authentication")) {
      let authentication = JSON.parse(localStorage.getItem("authentication"));
      headers["jwt_token"] = authentication.jwt_token;
    }

    const response = await axios({
      url: `${configConstants.API_URL_SETEST}${url}`,
      auth: authDefault,
      headers: headers,
      ...data,
    });

    return response;
  } catch (error) {
    return {
      status: false,
      message: "Đã xảy ra lỗi vui lòng thử lại sau!",
    };
  }
}

export async function apiRequestLogout(url = "/", data = {}) {
  try {
    let headers = {
      "X-API-KEY": configConstants.API_KEY,
    };
    if (localStorage.getItem("authentication")) {
      let authentication = JSON.parse(localStorage.getItem("authentication"));
      headers["jwt_token"] = authentication.jwt_token;
    }
    
    await axios({
      url: `${url}`,
      method: "POST",
      auth: authDefault,
      headers: headers,
      ...data,
    });

  } catch (error) {
   
  }
}
