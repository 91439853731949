import React, { useRef, useEffect } from 'react';

var audioElement;
function PopUpVocabularyDetail(props) {
    let { width, data, img_url, close } = props;

    const [tab, set_tab] = React.useState(false)
    const ref = useRef();

    useOnClickOutside(ref, () => close(false));

    // console.log(data)

    function getImage(imageJson) {
        const myObject = JSON.parse(imageJson);
        myObject.forEach(element => {
            const index = element.search('300x200');
            if (index >= 0) {
                return element;
            }
        });
        return myObject[0];
    }

    function handleClickItem(e, $url) {
        e.preventDefault();
        if (audioElement) {
            audioElement.pause();
        }
        audioElement = new Audio($url);
        const playPromise = audioElement.play();

        if (playPromise !== undefined) {
            playPromise
                .then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    console.log("audio played auto");
                })
                .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    console.log("playback prevented");
                });
        }
    }

    function useOnClickOutside(ref, handler) {
        useEffect(
            () => {
                const listener = (event) => {
                    // Do nothing if clicking ref's element or descendent elements
                    if (!ref.current || ref.current.contains(event.target)) {
                        return;
                    }
                    handler(event);
                };
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            },
            [ref, handler]
        );
    }

    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="" >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" ref={ref} style={{ width: width, minHeight: 380, position: 'relative' }}>
                {/* <div className='text-right'>
                    <span className='cursor-pointer' onClick={() => close(false)}>X</span>
                </div> */}
                {!tab && <div>
                    <div className='content'>
                        <div className='__img-detail'>
                            <img src={img_url + getImage(data?.image)} alt="thumb" />
                        </div>
                    </div>
                    <div className="__vocabulary d-flex justify-content-between align-items-center">
                        <div>
                            <span>{data?.vocabulary}</span>
                            <span>{data?.spell}</span>
                        </div>
                        <img src="/../../assets/images/student/thanhtich/ico_voice.png" alt="voice" onClick={(e) => handleClickItem(e, img_url + data?.audio)} />
                    </div>
                    <p className="__vi_mean mb-5">{JSON.parse(data?.vi_mean)}</p>
                    <p className='mb-5'>{JSON.parse(data?.en_mean)}</p>
                </div>}
                {tab && <div>
                    <div className="__vocabulary d-flex justify-content-between align-items-center">
                        <div>
                            <span>{data?.vocabulary}</span>
                        </div>
                        <img src="/../../assets/images/student/thanhtich/ico_voice.png" alt="voice" onClick={(e) => handleClickItem(e, img_url + data?.audio)} />
                    </div>
                    <p className="__vi_mean mb-5">{JSON.parse(data?.vi_mean)}</p>
                    <p className="__vi_mean mb-5">{data?.en_vi_sentence}</p>
                    <p>{JSON.parse(data?.en_vi_sentence_mean)[0]}</p>
                </div>}
                <div className="form-sunE-button">
                    <button className='btn-flash-popup-vocabulary'>
                        <img className='img-flash-popup-vocabulary' src="/../../assets/images/Group_7168.png" alt="voice" onClick={() => set_tab(!tab)} />
                    </button>
                    {/* <button className="btn-line-blue" onClick={() => close(false)}>Đóng</button> */}
                </div>
            </div>
        </div>
    )
}

export { PopUpVocabularyDetail };