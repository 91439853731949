import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import range from "lodash-es/range";
import { scheduleActions } from "./../../_actions";
import moment from "moment";
import "./style.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { scheduleConstants } from "../../_constants";
import { isEmpty } from "lodash";

const weekDays = ["T2", "T3", "T4", "T5", "T6", "T7", "CN"];

const todayObj = dayjs();

function CalendaSchedule(props) {
  const dispatch = useDispatch();
  const schedules = useSelector((state) => state.schedules);
  const status = schedules.status;
  const date = new Date();
  let {
    changeDateCalendaSchedule,
    collapse,
    showAll,
    showMonth,
    role,
    student_id,
    hideEvent,
    class_id,
    homePageTeacherCalendaSchedule,
  } = props;

  role = role || "teacher";
  const [fullHeight, setFullHeight] = useState(false);
  const [dayObj, setDayObj] = useState(
    dayjs(
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    )
  );
  const thisYearSelected = schedules.selectDate
    ? parseInt(moment(schedules.selectDate)?.format("YYYY"))
    : dayObj.year();
  const thisMonthSelected = schedules.selectDate
    ? parseInt(moment(schedules.selectDate)?.format("MM") - 1)
    : dayObj.month();
  const thisDateSelected = schedules.selectDate
    ? parseInt(moment(schedules.selectDate)?.format("DD"))
    : dayObj.date();
  const thisWeekSelected = weekCount(
    thisYearSelected,
    thisMonthSelected,
    thisDateSelected
  );

  const thisYear = dayObj.year();
  const thisMonth = dayObj.month();
  const thisDate = dayObj.date();

  const thisWeek = weekCount(thisYear, thisMonth, thisDate);
  const daysInMonth = dayObj.daysInMonth();

  const dayObjOf1 = dayjs(`${thisYear}-${thisMonth + 1}-1`);
  let weekDayOf1 = dayObjOf1.day() - 1;

  if (weekDayOf1 < 0) {
    weekDayOf1 = 6;
  }
  const dayObjOfLast = dayjs(`${thisYear}-${thisMonth + 1}-${daysInMonth}`);
  let weekDayOfLast = dayObjOfLast.day() - 1;
  if (weekDayOfLast < 0) {
    weekDayOfLast = 6;
  }

  const handlePrev = () => {
    let dayObjTmp = dayObj.subtract(1, "month");
    dispatch(
      scheduleActions.getStatus(
        dayObjTmp.month() + 1,
        dayObjTmp.year(),
        role,
        student_id,
        class_id
      )
    );
    setDayObj(dayObjTmp);
  };
  const handleNext = () => {
    let dayObjTmp = dayObj.add(1, "month");
    dispatch(
      scheduleActions.getStatus(
        dayObjTmp.month() + 1,
        dayObjTmp.year(),
        role,
        student_id,
        class_id
      )
    );
    setDayObj(dayObjTmp);
  };

  const [selectDate, setSelectDate] = useState({
    year: schedules.dateSelectedCalendar
      ? parseInt(moment(schedules.dateSelectedCalendar).format("YYYY"))
      : localStorage.getItem("date_selected")
      ? parseInt(
          moment(JSON.parse(localStorage.getItem("date_selected"))).format(
            "YYYY"
          )
        )
      : thisYear,
    month: schedules.dateSelectedCalendar
      ? parseInt(
          parseInt(moment(schedules.dateSelectedCalendar).format("MM")) - 1
        )
      : localStorage.getItem("date_selected")
      ? parseInt(
          parseInt(
            moment(JSON.parse(localStorage.getItem("date_selected"))).format(
              "MM"
            )
          ) - 1
        )
      : thisMonth,
    day: schedules.dateSelectedCalendar
      ? parseInt(moment(schedules.dateSelectedCalendar).format("DD"))
      : localStorage.getItem("date_selected")
      ? parseInt(
          moment(JSON.parse(localStorage.getItem("date_selected"))).format("DD")
        )
      : todayObj.date(),
  });

  function selectDay(year, month, day) {
    localStorage.setItem(
      "date_selected",
      JSON.stringify(`${year}-${parseInt(month) + 1}-${day}`)
    );
    setSelectDate({
      year,
      month,
      day,
    });
    dispatch({
      type: scheduleConstants.SET_DATE_SELECTED_CALENDAR,
      time: `${year}-${parseInt(month) + 1}-${day}`,
    });
    changeDateCalendaSchedule({
      year,
      month,
      day,
    });
  }

  useEffect(() => {
    let currentDate = new Date();
    dispatch(
      scheduleActions.getStatus(
        parseInt(currentDate.getMonth()) + 1,
        currentDate.getFullYear(),
        role,
        student_id,
        class_id
      )
    );

    return () => {
      dispatch({
        type: scheduleConstants.GET_STATUS,
        data: {
          in_complete: [],
          complete: [],
          has_log: [],
        },
      });
    };
  }, []);

  const renderClass = useCallback(
    (day, month, year) => {
      if (hideEvent) {
        return "";
      }
      let date = year + "-" + parseInt(month) + "-" + day;

      if (status.in_complete.indexOf(moment(date).format("YYYY-MM-DD")) != -1) {
        return " missing";
      } else if (
        status.complete.indexOf(moment(date).format("YYYY-MM-DD")) != -1
      ) {
        return " complete";
      } else if (
        status?.has_log?.length > 0 &&
        status.has_log?.indexOf(moment(date).format("YYYY-MM-DD")) != -1
      ) {
        return " active_date";
      } else {
        return "";
      }
    },
    [status]
  );

  function showFullHeight() {
    setFullHeight(!fullHeight);
  }

  function renderClassHideCollapse(day, month, year) {
    let dateFormat =
      selectDate.year +
      "-" +
      (parseInt(selectDate.month) + 1) +
      "-" +
      selectDate.day;

    let dateWeekToday = moment(dateFormat);
    let dowDateWeekToday = parseInt(dateWeekToday.day()) + 1;
    let listDateShowCollapse = [moment(dateFormat).format("YYYY-MM-DD")];
    if (dowDateWeekToday == 2) {
      for (let i = 1; i <= 6; i++) {
        listDateShowCollapse.push(
          moment(dateFormat, "YYYY-MM-DD").add("days", i).format("YYYY-MM-DD")
        );
      }
    } else if (dowDateWeekToday == 1) {
      for (let i = 1; i <= 6; i++) {
        listDateShowCollapse.push(
          moment(dateFormat, "YYYY-MM-DD")
            .subtract("days", i)
            .format("YYYY-MM-DD")
        );
      }
    } else {
      for (let i = 2; i < dowDateWeekToday; i++) {
        listDateShowCollapse.push(
          moment(dateFormat, "YYYY-MM-DD")
            .subtract("days", i - 1)
            .format("YYYY-MM-DD")
        );
      }
      for (let i = 1; i <= 8 - dowDateWeekToday; i++) {
        listDateShowCollapse.push(
          moment(dateFormat, "YYYY-MM-DD").add("days", i).format("YYYY-MM-DD")
        );
      }
    }

    let date = year + "-" + month + "-" + day;
    if (listDateShowCollapse.indexOf(moment(date).format("YYYY-MM-DD")) == -1) {
      // return ' hide_collapse';
    } else {
      // return '';
    }
    return "";
  }

  function dequyWeek(days, week, total) {
    days = parseInt(days) + 7;
    week++;
    if (total > days) {
      return dequyWeek(days, week, total);
    } else {
      return week;
    }
  }

  function weekCount(year, month_number, total) {
    var firstOfMonth = new Date(year, month_number, 1);
    let week = 1;
    let days = firstOfMonth.getDay() == 0 ? 1 : 8 - firstOfMonth.getDay();
    if (total > days) {
      week = dequyWeek(days, week, total);
    }
    return week;
  }

  const renderClassShot = (date) => {
    if (fullHeight || !collapse) {
      return "";
    } else {
      let className = "";
      if (
        thisMonthSelected == dayObj.month() &&
        thisYearSelected == dayObj.year()
      ) {
        let week = weekCount(thisYearSelected, thisMonthSelected, date);
        className = " hide-date-custom";
        if (thisWeekSelected == week) {
          className = "";
        }
      }
      return className;
    }
  };

  const showWeekDayOf1 = () => {
    if (fullHeight || !collapse) {
      return true;
    } else {
      let isHide = true;
      if (
        thisMonthSelected == dayObj.month() &&
        thisYearSelected == dayObj.year()
      ) {
        var firstOfMonth = new Date(thisYear, thisMonth, 1);
        if (
          (firstOfMonth.getDay() == 1 && thisWeekSelected == 1) ||
          thisWeekSelected != 1
        ) {
          isHide = false;
        }
      }
      // console.log('isHide ====', isHide)
      return isHide;
    }
  };
  
  return (
    <div
      className={
        (fullHeight || showAll ? "fullHeight " : "") +
        (collapse ? " collapse" : "") +
        (showMonth ? " showMonth" : "")
      }
    >
      <div className="calendar">
        <div
          className={
            "header day-calendar-custom" +
            (collapse && !showMonth ? "hide" : "")
          }
        >
          <button
            type="button"
            className={"nav nav--prev"}
            onClick={handlePrev}
          >
            <img
              alt="ico_left_calender"
              src="/assets/images/icon/ico_left_calender.png"
            />
          </button>
          <div className="datetime">{"Tháng " + dayObj.format("M/YYYY")}</div>
          <button
            type="button"
            className={"nav nav--prev"}
            onClick={handleNext}
          >
            <img
              alt="ico_right_calender"
              src="/assets/images/icon/ico_right_calender.png"
            />
          </button>
        </div>
        <div className="week-container fix-nowap-row-calendar">
          {weekDays.map((d) => (
            <div
              className={`${
                homePageTeacherCalendaSchedule
                  ? "homePageTeacherCalendaSchedule"
                  : ""
              } week-cell flex-1`}
              key={d}
            >
              {d}
            </div>
          ))}
        </div>
        <div className="day-container">
          {showWeekDayOf1() &&
            range(weekDayOf1).map((i) => (
              <div className={"day-box"} key={i}>
                <div
                  className={
                    `${
                      homePageTeacherCalendaSchedule
                        ? "homePageTeacherCalendaSchedule"
                        : ""
                    } day-cell day-cell--faded` +
                    renderClassHideCollapse(
                      dayObjOf1.subtract(weekDayOf1 - i, "day").date() + 1,
                      todayObj.month(),
                      todayObj.year()
                    ) +
                    renderClass(
                      dayObjOf1.subtract(weekDayOf1 - i, "day").date() + 1,
                      todayObj.month(),
                      todayObj.year()
                    )
                  }
                  key={i}
                >
                  {dayObjOf1.subtract(weekDayOf1 - i, "day").date()}
                </div>
              </div>
            ))}

          {range(daysInMonth).map((i) => (
            <div className={"day-box" + renderClassShot(i + 1)} key={i}>
              <div
                className={
                  `${
                    homePageTeacherCalendaSchedule
                      ? "homePageTeacherCalendaSchedule"
                      : ""
                  } rel day-cell day-cell--in-month${
                    i + 1 == todayObj.date() &&
                    thisMonth == todayObj.month() &&
                    thisYear == todayObj.year()
                      ? " day-cell--today"
                      : ""
                  }${
                    i + 1 == selectDate.day &&
                    selectDate.month == thisMonth &&
                    selectDate.year == thisYear
                      ? " active"
                      : ""
                  }` +
                  renderClass(i + 1, thisMonth + 1, thisYear) +
                  renderClassHideCollapse(i + 1, thisMonth + 1, thisYear) +
                  renderClassShot(i + 1)
                }
                key={i}
                onClick={() => selectDay(thisYear, thisMonth, i + 1)}
              >
                {i + 1}
                <img
                  alt="ico_check_calendar"
                  src="/assets/images/icon/ico_check_calendar.png"
                  className="img-check-calendar"
                />
                <img
                  alt="ico_missing_calendar"
                  src="/assets/images/icon/ico_missing_calendar.png"
                  className="img-missing-calendar"
                />
                <img
                  alt="ico_active_calendar_green"
                  src="/assets/images/icon/ico_green_circle.png"
                  className="img-active_dot-calendar"
                />
              </div>
            </div>
          ))}

          {range(6 - weekDayOfLast).map((i) => (
            <div className={"day-box"} key={i}>
              <div
                className={
                  `${
                    homePageTeacherCalendaSchedule
                      ? "homePageTeacherCalendaSchedule"
                      : ""
                  } day-cell day-cell--faded` +
                  renderClassHideCollapse(
                    dayObjOfLast.add(i + 1, "day").date(),
                    todayObj.month() + 2,
                    todayObj.year()
                  ) +
                  renderClass(
                    dayObjOfLast.add(i + 1, "day").date(),
                    todayObj.month() + 2,
                    todayObj.year()
                  )
                }
                key={i}
              >
                {dayObjOfLast.add(i + 1, "day").date()}
              </div>
            </div>
          ))}
        </div>
      </div>
      {collapse && (
        <div className="btn-collapse" onClick={() => showFullHeight()}>
          {fullHeight ? (
            <img
              src="/assets/images/student/ico_dropup_blue.png"
              alt="ico_dropup_blue"
              className="ico-calender-collapse"
            />
          ) : (
            <img
              src="/assets/images/student/ico_dropdown_blue.png"
              alt="ico_dropdown_blue"
              className="ico-calender-collapse"
            />
          )}
        </div>
      )}
    </div>
  );
}

export { CalendaSchedule };
