import React, { useEffect, useState } from "react";
import { HeaderCloud, HeaderLogoRegister } from "../../_components/Header";
import { stepAuthConstants } from "../../_constants/auth";
import { apiCaller, history } from "../../_helpers";
import AccountType from "./components/AccountType";
import CreateAccount from "./components/CreateAccount/index";
import RegisterSuccess from "./components/RegisterSuccess";
import UpdateInformation from "./components/UpdateInformation";
import { userConstants } from "../../_constants";
import API from "../../_apis/APIConstants";
import APIBase from "../../_base/APIBase";
import { useDispatch } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import AddClassName from "./components/AddClassName";
import './register.scss'
import AddressStudying from "./components/AddressStudying";
import { useParams } from "react-router-dom";
import { registerValue } from "../../_constants/register";
function RegisterPage() {
  const params = useParams()
  const orgLevel = params?.orgLevel;
  const [registerInfo, setRegisterInfo] = useState(registerValue?.[orgLevel] || undefined);
  const [step, setStep] = useState(stepAuthConstants.STEP_ADDRESS_STUDYING);
  const [dataAddressStudying, setDataAddressStudying] = useState({
    province: '',
    district: '',
    school_id: '',
    school: '',
    organization: '',
  });
  const [dataCreateAccount, setDataCreateAccount] = useState({
    email: "",
    password: "",
    rePassword: "",
  });

  const [dataStepRole, setDataStepRole] = useState({
    role: 'student',
    grade: "",
    schoolType: "",
  });
  const [dataClassName, setDataClassName] = useState({})
  const [dataUpdateInfo, setDataUpdateInfo] = useState({
    fullname: "",
    birthday: "",
    gender: "",
    phone: "",
    // province: "",
    // district: "",
    // school: "",
    onRegister: false,
    // school_id: "",
  });
  const [statusRegister, setStatusRegister] = useState({
    type: "",
    message: "",
  });
  const [messageTeacher, setMessageTeacher] = useState("");
  const [popupMessageRegisterTeacher, setPopupMessageRegisterTeacher] =
    useState(false);
  const [saveDataUser, setSaveDataUser] = useState({});
  const [statusSuccessRegisterTeacher, setStatusSuccessRegisterTeacher] =
    useState();

  useEffect(() => {
    if (dataUpdateInfo.onRegister) {
      onRegister();
    }
  }, [dataUpdateInfo]);

  const onClickYes = () => {
    const data = {
      email: dataCreateAccount.email,
      password: dataCreateAccount.password,
      re_password: dataCreateAccount.rePassword,
      role: dataStepRole.role,
      class: dataStepRole.grade,
      school_type: dataStepRole.schoolType,
      school: dataAddressStudying.school,
      birthday: dataUpdateInfo.birthday,
      grade_id: dataStepRole.grade,
      fullname: dataUpdateInfo.fullname,
      gender: dataUpdateInfo.gender,
      province_alias: dataAddressStudying.province,
      district_alias: dataAddressStudying.district,
      phone: dataUpdateInfo.phone,
      year_of_birth: dataUpdateInfo.birthday?.getFullYear(),
      school_id: dataAddressStudying?.school_id,
      organization_code: dataAddressStudying?.organization,
    };
    data.birthday = moment(dataUpdateInfo.birthday).format("YYYY-MM-DD");
    setPopupMessageRegisterTeacher(false);
    history.push(`/register/authentication`, { data: data });
  };

  const onRegister = async () => {
    const data = {
      email: dataCreateAccount.email,
      password: dataCreateAccount.password,
      re_password: dataCreateAccount.rePassword,
      role: dataStepRole.role,
      class: dataStepRole.grade,
      school_type: dataStepRole.schoolType,
      school: dataAddressStudying.school,
      birthday: dataUpdateInfo.birthday,
      grade_id: dataStepRole.grade,
      fullname: dataUpdateInfo.fullname,
      gender: dataUpdateInfo.gender,
      province_alias: dataAddressStudying.province,
      district_alias: dataAddressStudying.district,
      phone: dataUpdateInfo.phone,
      year_of_birth: dataUpdateInfo.birthday?.getFullYear(),
      school_id: dataAddressStudying?.school_id,
      organization_code: dataAddressStudying?.organization,
    };
    if (userConstants.ROLE_TEACHER == dataStepRole.role) {
      data.birthday = moment(dataUpdateInfo.birthday).format("YYYY-MM-DD");
      try {
        let urlApi = `${API.baseURL}${API.register_teacher}`;
        const result = await APIBase.apiBaseCaller("POST", urlApi, data);
        if (result.status) {
          setMessageTeacher(
            "Đăng ký tài khoản thành công. Vui lòng xác thực để có thể đăng nhập và sử dụng tài khoản."
          );
          setPopupMessageRegisterTeacher(true);
          setStatusSuccessRegisterTeacher(true);
        } else {
          if (!isEmpty(result?.message?.errors?.phone[0])) {
            setMessageTeacher(result?.message?.errors?.phone[0]);
            setPopupMessageRegisterTeacher(true);
            setStatusSuccessRegisterTeacher(false);
          }
        }
      } catch (error) {
        if (!isEmpty(error?.message?.errors?.phone[0])) {
          setMessageTeacher(error?.message?.errors?.phone[0]);
          setPopupMessageRegisterTeacher(true);
          setStatusSuccessRegisterTeacher(false);
        }
      }
    } else {
      const newData = {
        ...data,
        class_nickname: dataClassName?.class_nickname,
        auto_active: 1
      }
      apiCaller("/api_register/register", "POST", newData).then((res) => {
        if (res.status) {
          setStatusRegister({
            type: "success",
            message: res.msg,
          });
        }
      }).catch((error) => {
        setMessageTeacher(error);
        setPopupMessageRegisterTeacher(true);
      });
    }
  };

  const handleResetState = () => {
    setStatusRegister({
      type: "",
      message: '',
    });
    setStep(stepAuthConstants.STEP_ADDRESS_STUDYING)
    setDataUpdateInfo({
      fullname: "",
      birthday: "",
      gender: "",
      phone: "",
      onRegister: false,
    })
    setDataStepRole({
      role: 'student',
      grade: "",
      schoolType: "",
    })
    setDataAddressStudying({
      province: '',
      district: '',
      school_id: '',
      school: ''
    });
    setDataCreateAccount({
      email: "",
      password: "",
      rePassword: "",
    });
  }

  return (
    <div className="bg-register rel">
      {!!registerInfo && 
        <HeaderLogoRegister 
          srcImg={registerInfo?.logo} 
          name={registerInfo?.name} 
          color={registerInfo?.color}
          path={registerInfo?.path}
        />
      }
      {statusRegister.type !== "success" ? (
        <>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_ADDRESS_STUDYING
                  ? "block"
                  : "none",
            }}
          >
            <AddressStudying
              setStep={setStep}
              data={dataAddressStudying}
              setData={setDataAddressStudying}
              registerInfo={registerInfo}
              setRegisterInfo={setRegisterInfo}
            />
          </div>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_CREATE_ACCOUNT
                  ? "block"
                  : "none",
            }}
          >
            <CreateAccount
              setStep={setStep}
              data={dataCreateAccount}
              setData={setDataCreateAccount}
              
            ></CreateAccount>
          </div>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_ACCOUNT_TYPE ? "block" : "none",
            }}
          >
            <AccountType
              setStep={setStep}
              data={dataStepRole}
              setData={setDataStepRole}
              step={step}
              
            ></AccountType>
          </div>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_ADD_CLASS_NAME ? "block" : "none",
            }}
          >
            <AddClassName
              setStep={setStep}
              data={dataClassName}
              setData={setDataClassName}
              step={step}
              role={dataStepRole.role}
            />
          </div>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_UPDATE_ACCOUNT
                  ? "block"
                  : "none",
            }}
          >
            <UpdateInformation
              setStep={setStep}
              data={dataUpdateInfo}
              setData={setDataUpdateInfo}
              onRegister={onRegister}
              step={step}
              role={dataStepRole.role}
              schoolType={dataStepRole.schoolType}
              messageTeacher={messageTeacher}
              popupMessageRegisterTeacher={popupMessageRegisterTeacher}
              setPopupMessageRegisterTeacher={setPopupMessageRegisterTeacher}
              dataUser={saveDataUser}
              statusSuccessRegisterTeacher={statusSuccessRegisterTeacher}
              onClickYes={onClickYes}
            ></UpdateInformation>
          </div>
        </>
      ) : (
        <RegisterSuccess handleReset={handleResetState} ></RegisterSuccess>
      )}
    </div>
  );
}

export { RegisterPage };
