import React from 'react';
import './style.css';

function PopUpRemoveStudentInClass(props) {
    let { message, onClickYes, onClickNo , width} = props;
    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ width: width }}>
                <p className="text-center">Bạn có chắc chắn muốn mời <br /><span className="bold" style={{fontSize: '16px'}}>{message}</span><br />ra khỏi lớp?</p>
                <div className="flex-m form-sunE-button">
                    <button className="btn-default btn-line-blue btn-custom-width" onClick={onClickNo}>Không</button>
                    <button className="btn-line-blue btn-custom-width" onClick={onClickYes}>Có</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpRemoveStudentInClass };