import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import { HomePage } from "../../_screens";

export const RouteRedirectToAdmin = ({
  component: Component,
  additionalProps,
  ...rest
}) => {
  return (
    <Route
      exact={true}
      {...rest}
      render={(props) => {
        return (
          <div className="sunE-container bg-main min-hei-100 rel bg_auth">
            <Component {...props} {...additionalProps} />
          </div>
        );
      }}
    />
  );
};

RouteRedirectToAdmin.propTypes = {
  location: PropTypes.any,
  component: PropTypes.any,
};
