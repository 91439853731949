import { userConstants } from "../../_constants";
var initialState = {
  content_message: {
    room_id: "",
    to_user_id: "",
    subject: "",
    content: "",
    reply_for_id: "",
    class_id: "",
  },
  data_message: {
    data: [],
  },
  detail_message: {
    data: [],
  },
  contact_info: {
    data: [],
    base_url: "",
  },
  room_chat: "",
  total_msg: {
    number_msg_new: "",
    number_system_new: "",
  },
};

const messages = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.GET_CLASS_MESSAGE:
      return {
        data_message: action.data_message,
      };
    case userConstants.RESET_CLASS_MESSAGE:
      return {
        data_message: {},
      };
    case userConstants.GET_CLASS_NOTIFICATION:
      return {
        data_message: {
          data: action.data,
        },
      };
    case userConstants.GET_DETAIL_MESSAGE:
      return {
        ...state,
        detail_message: {
          data: action.data,
        },
      };
    case userConstants.SEND_MESSAGE:
      return {
        ...state,
        content_message: action.data,
      };
    case userConstants.GET_CONTACT_INFO:
      return {
        ...state,
        contact_info: {
          data: action.data,
          base_url: action.base_url,
        },
      };
    case userConstants.GET_ROOM_CHAT:
      return {
        ...state,
        room_chat: action.data,
      };
    case userConstants.GET_TOTAL_MESSAGE:
      return {
        ...state,
        total_msg: {
          number_msg_new: action.number_msg_new,
          number_system_new: action.number_system_new,
        },
      };
    default:
      return state;
  }
};

export { messages };
