import { popupConstants } from '../../_constants';
var initialState = {
    'showFormResetTimeTable': false,
    'showFormAddTimeTable': false,
    'showFormDetailTimeTable': false,
    'showFormAddScheduleYear': false,
    'showFormAddStudent': false,
    'showFormApplyClass': false,
    'showFilterCurriculum': false,
    'showAddFile': false,
    'addFileCompleteResource': false,
    'showFormAddTimeSchedule': false,
    'showFormRemind': false,
};

const Popup = (state = initialState, action) => {
    switch (action.type) {
        case popupConstants.CLEAR_ALL_POPUP:
            return {
                ...state,
                ...initialState
            };
        case popupConstants.SHOW_FORM_POPUP:
            return {
                ...action.data
            };
        default: return state;
    }
};

export { Popup };