export const configConstants = {
    API_URL: "",
    API_KEY: "",
    AUTH_BASIC_USER_NAME: "",
    AUTH_BASIC_PASSWORD: "",
    APP_ID_GOOGLE: "",
    SCOPE_LOGIN_GOOGLE: "",
    APP_ID_FACEBOOK: "",
    BASE_URL: "",
    API_URL_SETEST: "",
    API_TIMEOUT: 12000,
    LOGIN_VIA_GOOLE: "LOGIN_VIA_GOOLE",
    LOGIN_VIA_FACEBOOK: "LOGIN_VIA_FACEBOOK",
    LOGIN_VIA_APPLE: "LOGIN_VIA_APPLE",
    APP_ID_APPLE: "",
    APPLE_REDIRECT_URL: "",
};
