import React, { Fragment } from 'react';
import './style.css';

function PopUpHelp(props) {
    let { onClickNo, width} = props;
    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical help-popup" style={{ width: width }}>
                <h2 className="text-center">Hướng dẫn</h2>
                <p>- Ấn giữ và bôi đen từ hoặc cụm từ cần sửa. Sau đó sẽ xuất hiện pop up với công cụ <span className="bold">"Sửa"</span>. Giáo viên chọn xóa, sửa và giải thích theo nhu cầu.<br/>
                - Đối với những từ và cụm từ đã sửa, giáo viên chỉ cần nhấn vào từ thì hiện lên pop up với các thông tin được lưu từ lần cuối giáo viên sửa. Giáo viên có thể <span className="bold">"Hoàn tác"</span> hoặc <span className="bold">"Cập nhật"</span> thêm nội dung cần sửa</p>
                <div className="flex-m form-sunE-button center-flex">
                    <button className={"wd-c btn-line-blue"} onClick={onClickNo}>Đóng</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpHelp };