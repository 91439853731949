export const registerValue = {
  namdinh: {
    path: 'namdinh',
    province_alias: 'nam_Dinh_',
    district_alias: '',
    organization: 'Nam Định',
    represent_code: 'ND1',
    logo: '/assets/images/Logo_ND.png',
    name: 'Sở giáo dục và đào tạo Nam Định',
    color: '#007FBF'
  },
  phucyen: {
    path: 'phucyen',
    province_alias: 'vinh_phuc',
    district_alias: 'thanh_pho_phuc_yen',
    organization: 'Phúc Yên',
    represent_code: 'PY1',
    logo: '/assets/images/logo_PY1.png',
    name: 'Phòng Giáo dục thành phố\nPhúc Yên - Vĩnh Phúc',
    color: '#3493B8'
  },
  minhduc: {
    path: 'minhduc',
    province_alias: 'ha_noi',
    district_alias: 'huyen_Ung_hoa',
    organization: 'Minh Đức',
    represent_code: 'MD1',
    logo: '/assets/images/logo_MD1.png',
    name: 'Trường THCS Minh Đức - Ứng Hoà - Hà Nội',
    color: '#3493B8'
  },
}