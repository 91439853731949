import "./ContactPage.style.scss";
import { HeaderNews } from "../../_components/Header";
import {
  TypeHeaderNewsItem,
  NameTitleNewsItem,
} from "../../_constants/headerNews";
import FooterNews from "../../_components/FooterNews/FooterNews";
import ButtonNews from "../../_components/Button/ButtonNews";
import { HashLink } from "react-router-hash-link";
import InputNews from "../../_components/Input/InputNews";
import { contactLogic } from "./ContactPage.logic";
import TextAreaNews from "../../_components/Input/TextAreaNews";
import ModalSuccessMsg from "../../_components/Modal/ModalSuccessMsg/ModalSuccessMsg";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import TitleNews from "../../_components/Header/HeaderNews/TitleNews";
import InputText from "../../_components/Auth/InputText";

const ContactPage = () => {
  let {
    email,
    phone,
    nameUser,
    emailError,
    setEmailError,
    phoneWarning,
    setPhoneWarning,
    typeRatio,
    isVisibleModal,
    handleChangeRatio,
    handleCloseModal,
    handleSendMsg,
    onChangeName,
    onChangeEmail,
    onChangePhone,
    onBlurField,
    detailMsg,
    handleMsgContact,
    msgContact,
    nameUserError,
    setNameUserError,
  } = contactLogic();

  return (
    <div className="contactPage commondPage">
      <HeaderNews type={TypeHeaderNewsItem.TEACHER} />
      {/* <TitleNews title={NameTitleNewsItem.CONTACT} /> */}
      <div className="content">
        <div className="content_1 flex-between">
          <div className="flex-column col-45 content_left">
            <div className="mg-bottom-15 color-primary-green title_send_msg fz-16-mobile font_news_bold fz-20">
              Gửi tin nhắn
            </div>

            {!isVisibleModal ? (
              <div className="flex-column pos_rel">
                <div className="col-100 pos_rel form_input_contact">
                  <InputText
                    value={nameUser}
                    setValue={onChangeName}
                    type="text"
                    name="name"
                    placeholder="Họ và tên"
                    required={true}
                    // renderLabelIcon={() => renderAuthIcon("name")}
                    errorText={nameUserError}
                    setErrorText={setNameUserError}
                    onBlur={() => {
                      onBlurField("name");
                    }}
                  />
                  <InputText
                    value={email}
                    setValue={onChangeEmail}
                    type="text"
                    name="email"
                    placeholder="Địa chỉ Email"
                    required={true}
                    // renderLabelIcon={() => renderAuthIcon("email")}
                    errorText={emailError}
                    onBlur={() => {
                      onBlurField("email");
                    }}
                    setErrorText={setEmailError}
                  ></InputText>
                  <InputText
                    value={phone}
                    setValue={onChangePhone}
                    type="text"
                    name="phone"
                    placeholder="Số điện thoại"
                    required={true}
                    // renderLabelIcon={() => renderAuthIcon("phone")}
                    errorText={phoneWarning}
                    setErrorText={setPhoneWarning}
                    onBlur={() => {
                      onBlurField("phone");
                    }}
                  />

                  <div className="col-100 col-100-mobile content_left_ratio mg-top-15 mg-bottom-15">
                    <div className="font_news_bold title_ratio text_ratio">
                      Tôi muốn
                    </div>

                    <div className="flex-column">
                      <div
                        onClick={() => handleChangeRatio("care")}
                        className="flex-align pointer_cursor mg-bottom-0-5"
                      >
                        <img
                          src={`/assets/images/icon/${
                            typeRatio == "care"
                              ? "ico_ratio_active"
                              : "ico_ratio_none"
                          }.png`}
                          alt="Icon Ratio"
                          className="contain_image icon_ratio_contact"
                        />
                        <div className="mg-left-0-5 text_ratio ">
                          Quan tâm sản phẩm
                        </div>
                      </div>

                      <div
                        onClick={() => handleChangeRatio("opinion")}
                        className="flex-align pointer_cursor"
                      >
                        <img
                          src={`/assets/images/icon/${
                            typeRatio == "opinion"
                              ? "ico_ratio_active"
                              : "ico_ratio_none"
                          }.png`}
                          alt="Icon Ratio"
                          className="contain_image icon_ratio_contact"
                        />
                        <div className="mg-left-0-5 text_ratio">Góp ý</div>
                      </div>
                    </div>
                  </div>
                  <TextAreaNews
                    value={msgContact}
                    onChange={(e) => handleMsgContact(e.target.value)}
                    width={"80%"}
                    placeholder="Nội dung tin nhắn"
                  />
                </div>

                <div className="flex-center col-100-mobile col-100 mg-top-2">
                  <ButtonNews
                    width="15rem"
                    height="3rem"
                    onClick={() => handleSendMsg()}
                    border={"1px solid rgba(0, 0, 0, 0.2)"}
                    backgroundColor={"#fff"}
                    boxShadow={"2px 2px 5px white, 0 0 1px black"}
                    maxWidth={"40%"}
                    // maxHeight={"7vw"}
                  >
                    <div className="btn_text font_news_bold fz-13 fz-11-mobile">
                      GỬI NGAY
                    </div>
                  </ButtonNews>
                </div>
              </div>
            ) : (
              <ModalSuccessMsg
                isVisible={isVisibleModal}
                onClickClose={handleCloseModal}
                titleMsg="GỬI TIN NHẮN THÀNH CÔNG!"
                detailMsg={detailMsg}
              />
            )}
          </div>

          <div className="flex-column col-55 content_right">
            <div className="flex-column content_right_title">
              <div className="fz-11-mobile fz-24">
                Hãy liên hệ ngay hôm nay để trải nghiệm
              </div>
              <div className="color-red fz-26-mobile fz-35 font_news_bold">
                HỌC TIẾNG ANH
              </div>
              <div className="fz-14-mobile fz-24 font_news_bold_mobile">
                CÙNG
              </div>
              <div className="color-primary-green fz-24-mobile fz-35 font_news_bold">
                SUNDAY ENGLISH!
              </div>
            </div>
            <div className="hide display_flex_mobile col-100">
              <img
                src="/assets/images/banner_contact_mobile.png"
                alt="Banner Contact"
                className="col-100  content_banner_img"
              />
            </div>
          </div>
        </div>
        <div className="content_banner display_none_mobile col-100">
          <img
            src="/assets/images/banner_contact.png"
            alt="Banner Contact"
            className="col-100 content_banner_img"
          />
        </div>
      </div>

      <FooterNews />
      <ScrollFixed />
    </div>
  );
};

export default ContactPage;
