import { apiCaller } from "./../_helpers";
import { alertActions } from "./";
import { scheduleConstants, popupConstants } from "./../_constants";
import moment from "moment";
import { forEach } from "lodash";

export const scheduleActions = {
  getTimetable,
  getScheduleToday,
  addSchedule,
  addTimeTable,
  editTimeTable,
  deleteTimeTable,
  resetTimeTable,
  editSchedule,
  deleteSchedule,
  getScheduleYear,
  addScheduleYear,
  editScheduleYear,
  updateTimeForTimeTable,
  completeSchedule,
  getStatus,
  getScheduleStatus,
  deleteScheduleYear,
};

function getTimetable(start_time, end_time) {
  // BE required dont request param time
  const checkUrl =
    !!start_time && !!end_time
      ? `?start_time=${start_time}&&end_time=${end_time}`
      : "";
  return (dispatch) => {
    return apiCaller(
      "/API_schedule_lock/get_all_schedule",
      "GET",
      {},
      null,
      true
    ).then(
      (timeTable) => {
        // console.log(timeTable);
        dispatch({
          type: scheduleConstants.GET_TIME_TABLE,
          timeTable,
        });
      },
      () => {
        dispatch({
          type: scheduleConstants.GET_TIME_TABLE,
          timeTable: {
            data: [],
            valid_to: "",
          },
        });
      }
    );
  };
}

function addSchedule(data) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule/insert_schedule",
      "POST",
      data,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Thêm kế hoạch thành công",
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE,
            isShowPopup: true
          })
        );
      }
    );
  };
}

function deleteSchedule(data, time, student) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("id", data.id);
  urlencoded.append("update_all", data.update_all);
  return (dispatch) => {
    return apiCaller(
      "/API_schedule/delete_schedule",
      "DELETE",
      urlencoded,
      null,
      true
    ).then(() => {
      dispatch({
        type: scheduleConstants.RESET_DELETE_SCHEDULE_DAY,
      });
      // if (data.selectedDate) {
      //   dispatch(getScheduleToday(data.user_id, data.selectedDate));
      // } else {
      //   dispatch(getScheduleToday(data.user_id));
      // }
      let authentication = JSON.parse(localStorage.getItem("authentication"));
      if (authentication.role === "student") {
        dispatch(
          getScheduleStatus(
            moment(time).format("MM"),
            moment(time).format("YYYY")
          )
        );
      }
      dispatch(
        getStatus(
          moment(time).format("MM"),
          moment(time).format("YYYY"),
          authentication.role
        )
      );
    });
  };
}

function editSchedule(data) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule/edit_schedule",
      "PUT",
      data,
      null,
      true
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: "Sửa kế hoạch thành công",
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE,
          })
        );
      }
    );
  };
}

function getScheduleToday(id_user, time) {
  if (!time) {
    time = moment().format("YYYY-MM-DD");
  }
  return (dispatch) => {
    dispatch({
      type: scheduleConstants.SET_SELECT_DATE,
      time,
    });
    return apiCaller(
      "/API_schedule/get_all_schedule?id_user=" +
        id_user +
        "&start_time=" +
        time +
        "&end_time=" +
        time,
      "GET",
      {},
      null,
      true
    ).then((schedules) => {
      dispatch({
        type: scheduleConstants.GET_SCHEDULE_TODAY,
        today: schedules.data,
      });
      dispatch({
        type: scheduleConstants.SET_SELECT_DATE,
        time,
      });
    });
  };
}

function getScheduleYear(year) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule_year/list_schedule_year?year=" + year,
      "GET",
      {},
      null,
      true
    ).then((schedules) => {
      dispatch({
        type: scheduleConstants.GET_SCHEDULE_YEAR,
        year: schedules.data,
      });
      dispatch({
        type: scheduleConstants.ADD_DATA_SCHEDULE_YEAR,
        dataAddScheduleYear: {
          showForm: false,
        },
      });
    });
  };
}

function addTimeTable(data) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule_lock/schedule_lock",
      "POST",
      data,
      null,
      true
    ).then(
      () => {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        dispatch(
          alertActions.success({
            message: "Thêm thời khoá biểu thành công",
            screen: scheduleConstants.SCREEN_ADD_TIME_TABLE,
          })
        );

        dispatch(getTimetable());
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_ADD_TIME_TABLE,
          })
        );
      }
    );
  };
}

function editTimeTable(data) {
  return (dispatch) => {
    dispatch(getTimetable());
    return apiCaller(
      "/API_schedule_lock/schedule_lock",
      "PUT",
      data,
      null,
      true
    ).then(
      () => {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        dispatch(
          alertActions.success({
            message: "Sửa thời khóa biểu thành công.",
            screen: scheduleConstants.SCREEN_ADD_TIME_TABLE,
          })
        );
        dispatch(getTimetable());
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_ADD_TIME_TABLE,
          })
        );
      }
    );
  };
}

function updateTimeForTimeTable(data) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule_lock/schedule_config",
      "PUT",
      data,
      null,
      true
    ).then(
      () => {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        dispatch(
          alertActions.success({
            message: "Cập nhật thời khoá biểu hoạch thành công",
            screen: scheduleConstants.SCREEN_UPDATE_TIME_TIME_TABLE,
          })
        );
        dispatch(getTimetable());
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_UPDATE_TIME_TIME_TABLE,
          })
        );
      }
    );
  };
}

function deleteTimeTable(id) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("id", id);
  return (dispatch) => {
    dispatch(getTimetable());
    return apiCaller(
      "/API_schedule_lock/schedule_lock",
      "DELETE",
      urlencoded,
      null,
      true
    ).then(() => {
      dispatch(getTimetable());
    });
  };
}

function resetTimeTable(data) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule_lock/reset_schedule",
      "DELETE",
      data,
      null,
      true
    ).then(
      () => {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        dispatch(
          alertActions.success({
            message: "Đặt lại thời khoá biểu thành công",
            screen: scheduleConstants.SCREEN_RESET_TIME_TABLE,
          })
        );
        dispatch(getTimetable());
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_RESET_TIME_TABLE,
          })
        );
      }
    );
  };
}

function addScheduleYear(data) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule_year/create_schedule_year",
      "POST",
      data,
      null,
      true
    ).then(
      () => {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        dispatch(
          alertActions.success({
            message: "Thêm kế hoạch thành công",
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE_YEAR,
          })
        );
        dispatch(getScheduleYear(data.year));
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE_YEAR,
          })
        );
      }
    );
  };
}

function editScheduleYear(data) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule_year/edit_schedule_year",
      "PUT",
      data,
      null,
      true
    ).then(
      () => {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        dispatch(
          alertActions.success({
            message: "Sửa kế hoạch thành công",
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE_YEAR,
          })
        );
        dispatch(getScheduleYear(data.year));
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE_YEAR,
          })
        );
      }
    );
  };
}

function deleteScheduleYear(data) {
  return (dispatch) => {
    return apiCaller(
      "/API_schedule_year/edit_schedule_year",
      "PUT",
      data,
      null,
      true
    ).then(
      () => {
        dispatch({
          type: popupConstants.CLEAR_ALL_POPUP,
        });
        dispatch(
          alertActions.success({
            message: "Xóa kế hoạch thành công",
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE_YEAR,
          })
        );
        dispatch(getScheduleYear(data.year));
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE_YEAR,
          })
        );
      }
    );
  };
}

function completeSchedule(data, user_id, time) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("id", data.id);
  urlencoded.append("status", data.status);
  return (dispatch) => {
    return apiCaller(
      "/API_schedule/edit_schedule",
      "PUT",
      urlencoded,
      null,
      true
    ).then(() => {
      dispatch(getScheduleToday(user_id, time));
      dispatch(
        getStatus(
          moment(time).format("MM"),
          moment(time).format("YYYY"),
          "teacher"
        )
      );
    });
  };
}

function getStatus(month, year, role = "", student_id = "", class_id) {
  let uri = "/API_schedule/schedule_status?month=" + month + "&year=" + year;
  if (role === "teacher") {
  } else if (role === "student") {
    uri =
      "/api_student/log_learning_status?student_id=" +
      student_id +
      (class_id ? "&class_id=" + class_id : "") +
      "&month=" +
      month +
      "&year=" +
      year;
  } else {
    if (localStorage.getItem("authentication")) {
      let authentication = JSON.parse(localStorage.getItem("authentication"));
      if (authentication.role === "student") {
        uri =
          "/api_student/log_learning_status?student_id=" +
          authentication.id +
          (class_id ? "&class_id=" + class_id : "") +
          "&month=" +
          month +
          "&year=" +
          year;
      }
    }
  }

  return (dispatch) => {
    return apiCaller(uri, "GET", {}, null, true).then((status) => {
      let in_complete = [];
      let complete = [];
      let has_log = [];
      forEach(status.data, function (value) {
        if (value.status === "in_complete") {
          in_complete.push(value.date);
        }
        if (value.status === "complete") {
          complete.push(value.date);
        }
        if (value.status === "has_log") {
          has_log.push(value.date);
        }
      });
      dispatch({
        type: scheduleConstants.GET_STATUS,
        data: {
          in_complete,
          complete,
          has_log,
        },
      });
    });
  };
}

function getScheduleStatus(month, year) {
  let uri = "/API_schedule/schedule_status?month=" + month + "&year=" + year;

  return (dispatch) => {
    return apiCaller(uri, "GET", {}, null, true).then((status) => {
      let in_complete = [];
      let complete = [];
      let has_log = [];
      forEach(status.data, function (value) {
        if (value.status === "in_complete") {
          in_complete.push(value.date);
        }
        if (value.status === "complete") {
          complete.push(value.date);
        }
        if (value.status === "has_log") {
          has_log.push(value.date);
        }
      });
      dispatch({
        type: scheduleConstants.GET_STATUS,
        data: {
          in_complete,
          complete,
          has_log,
        },
      });
    });
  };
}
