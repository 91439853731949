import React, { Fragment } from 'react';
import './style.css';

function PopUpOnlyOneButton(props) {
    let { message, onClickNo, labelNo, width } = props;
    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ width: width }}>
                {typeof message === 'string' && <p className="text-center">{message}</p>}
                {typeof message === 'object' &&

                    message.map((item, i) => {
                        return (
                            <Fragment key={i}>
                                <p className="text-center">{item}</p>
                            </Fragment>
                        )
                    })

                }
                <div className="flex-m form-sunE-button text-center center-flex">
                    <button className="btn-line-blue" onClick={onClickNo}>{labelNo ? labelNo : 'Xong'}</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpOnlyOneButton };