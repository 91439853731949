export const scheduleConstants = {
    GET_SCHEDULE_TODAY: 'GET_SCHEDULE_TODAY',
    GET_TIME_TABLE: 'GET_TIME_TABLE',
    SCREEN_GET_TIME_TABLE: 'SCREEN_GET_TIME_TABLE',
    SCREEN_GET_SCHEDULE_TODAY: 'SCREEN_GET_SCHEDULE_TODAY',
    SCREEN_ADD_SCHEDULE: 'SCREEN_ADD_SCHEDULE',
    SCREEN_ADD_TIME_TABLE: 'SCREEN_ADD_TIME_TABLE',
    SCREEN_RESET_TIME_TABLE: 'SCREEN_RESET_TIME_TABLE',
    ADD_DETAIL_SCHEDULE_DAY: 'ADD_DETAIL_SCHEDULE_DAY',
    REMOVE_DETAIL_SCHEDULE_DAY: 'REMOVE_DETAIL_SCHEDULE_DAY',
    ADD_DELETE_SCHEDULE_DAY: 'ADD_DELETE_SCHEDULE_DAY',
    RESET_DELETE_SCHEDULE_DAY: 'RESET_DELETE_SCHEDULE_DAY',
    SCREEN_DELETE_SCHEDULE: 'SCREEN_DELETE_SCHEDULE',
    GET_SCHEDULE_YEAR: 'GET_SCHEDULE_YEAR',
    ADD_DATA_SCHEDULE_YEAR: 'ADD_DATA_SCHEDULE_YEAR',
    SCREEN_ADD_SCHEDULE_YEAR: 'SCREEN_ADD_SCHEDULE_YEAR',
    SET_SELECT_DATE: 'SET_SELECT_DATE',
    SET_DATE_SELECTED_CALENDAR: 'SET_DATE_SELECTED_CALENDAR',
    SCREEN_UPDATE_TIME_TIME_TABLE: 'SCREEN_UPDATE_TIME_TIME_TABLE',
    GET_STATUS: 'GET_STATUS',
    TEXT_ICON_SCHEDULE_STATUS: {
        'upcoming': {
            'title': 'Sắp diễn ra',
            'icon': 'ico_dangdienra.png',
            'class': 'orange'
        },
        'done': {
            'title': 'Đã hoàn thành',
            'icon': 'ico_succes_small.png',
            'class': 'success-text'
        },
        'over_time': {
            'title': 'Đã quá giờ',
            'icon': 'ico_quagio.png',
            'class': 'red'
        },
    },
    TEXT_TYPE_SCHEDULE: [
        {
            'id': 'personal',
            'name': 'Cá nhân'
        },
        // {
        //     'id': 'teaching_work',
        //     'name': 'Công tác giảng dạy'
        // },
        {
            'id': 'professional_activities',
            'name': 'Sinh hoạt chuyên môn'
        },
        {
            'id': 'test_evaluation',
            'name': 'Kiểm tra đánh giá'
        },
        {
            'id': 'other',
            'name': 'Khác'
        }
    ],
};