import { history } from '../../../../_helpers'
import { ChooseOrgLogic } from './ChooseOrg.logic'
import './ChooseOrg.style.scss'

export default function ChooseOrg() {
  const {organizationList, orgHover,setOrgHover,} = ChooseOrgLogic()
  return (
    <div className="bg-register rel">
      <div className="choose-org-container">
        <p className="choose-org-heading">Chọn tổ chức của bạn</p>
        <div className='org-list-container scrollbar-custom'>
          <div className='org-list'>
            {organizationList.map(item => (
              <div 
                className='org-item' 
                key={item.path} 
                onMouseEnter={() => setOrgHover(item)} 
                onMouseLeave={() => setOrgHover(null)}
                onClick={() => history.push(`/${item.path}`)}
              >
                <div className='org-item-img'>
                  <img src={item.logo} alt={item.path} />
                </div>
                <div className='org-item-name'>
                  <p style={{color: orgHover?.path === item.path ? item.color : 'unset'}}>{item.organization}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}